export const LANGUAGES = {
    "COMPANY_NAME": "Công ty cổ phần HOALAND",
    "LOGIN": "Đăng Nhập",
    "SIGN_IN": "Đăng Nhập",
    "SIGN_UP": "Đăng Ký",
    "SIGN_UP_NOW": "Bạn chưa có tài khoản? Đăng ký ngay!",
    "ENTER_OTP": "Nhập mã OTP",
    "LOGOUT": "Đăng Xuất",
    "CONFIRM": "Xác nhận",
    "FORGOT_PWD": "Đặt lại mật khẩu",
    "CREATE_AN_ACCOUNT": "Tạo tài khoản mới",
    "PWD_NOT_MATCH": "Mật khẩu không trùng khớp",
    "REGISTER": "Đăng ký",
    "HAVE_ACCOUNT": "Bạn đã có tài khoản",
    "RECEIVE_NEWSLETTER": "Nhận cập nhật tin tức",
    "RESET_PWD": "Quên mật khẩu",
    "REMEMBER_PASSWORD": "Nhớ mật khẩu",
    "ACCOUNT": "Tài khoản",
    "SUBMIT_PROPERTY": "Đăng BĐS",
    "FAVORITES": "Quan tâm",
    "COMPARE": "So sánh",
    "EDIT_PROFILE": "Sửa thông tin",
    "HELP": "Trợ giúp",
    "REQUIRED_FIELD": "Đây là trường bắt buộc",
    "TYPE_ACCOUNT_FOR": "Mở tài khoản cho",
    "SUBSCRIBE_OUR_NEWSLETTER": "Đăng ký bản tin",
    "STAY_UP_DATE": "để cập nhật tin tức BĐS mới nhất",
    "SUBSCRIBE": "Đăng ký",
    "FEEDBACK": "Phản hồi",
    "YOUR_FEEDBACK": "Hãy cho chúng tôi biết cảm nhận của bạn",
    "OUR_PARTNERS": "Đối tác của chúng tôi",
    "RESULT_SEARCH": "Kết quả tìm kiếm",
    "PLACEHOLDER_SEARCH": "Nhập khu vực bạn muốn tìm kiếm",
    "SLOGAN": "Kết nối tận tâm - Vươn tầm quốc tế",
    "CONSCIENTIOUS_CONNECTION": "Kết nối tận tâm",
    "GLOBAL_REACH": "Vươn tầm quốc tế",
    "NAV": {
        "HOME": "Trang chủ",
        "FOR_SELL": "Cần bán",
        "FOR_RENT": "Cho Thuê",
        "HELP": "Trợ giúp",
        "PRICE": "Giá",
        "TERMS_CONDITIONS": "Điều khoản & Sử dụng",
        "CONTACT": "Liên hệ",
        "ABOUT_US": "Về chúng tôi"
    },
    "BTN": {
        "SEE_ALL": "Xem tất cả",
        "SEE_MORE": "Xem thêm",
        "BOOKING": "Đặt lịch",
        "CONTACT_US": "Liên hệ tư vấn"
    },
    "FIELD": {
        "FULL_NAME": "Họ và tên",
        "PHONE_NUMBER": "Số điện thoại",
        "EMAIL": "Email",
        "PWD": "Mật Khẩu",
        "RE_PWD": "Nhập lại mật khẩu",
        "BUSINESS_NAME": "Tên công ty",
        "REPRESENTATIVE": "Người đại diện",
        "ADDRESS": "Địa chỉ",
        "ENTERPRISE": "Doanh nghiệp",
        "PERSONAL": "Cá nhân",
        "BUSINESS_REGISTER_CODE": "Số đăng ký kinh doanh",
        "BUSINESS_EMAIL": "Email doanh nghiệp",
        "BUSINESS_ADDRESS": "Địa chỉ công ty",
        "DATE_OF_BIRTH": "Ngày sinh",
        "IDENTITY_DOCUMENT": "Số CMND/CCCD",
        "CURRENT_PASSWORD": "Mật khẩu hiện tại",
        "NEW_PASSWORD": "Mật khẩu mới",
        "CONFIRM_NEW_PASSWORD": "Xác nhận mật khẩu mới"
    },
    "ERROR_CODE": {
        "USERNAME_EXIST": "Người dùng đã tồn tại",
        "PHONE_EXIST": "Số điện thoại đã tồn tại",
        "EMAIL_FORMAT": "Email không tồn tại",
        "EMAIL_EXIST": "Email đã tồn tại",
        "PHONE_NOT_EXIST": "Số điện thoại chưa đăng ký",
        "OTP_NOT_MATCHED": "Mã OTP không đúng",
        "UNDEFINED": "Lỗi không xác định",
        "INVALID_EMAIL": "Thông tin email không đúng"
    },
    "CALL_US_NOW": "Gọi ngay cho chúng tôi!",
    "PROPERTIES_FOR_YOU": "Gợi ý cho bạn",
    "LATEST_PROPERTIES": "BĐS mới nhất",
    "FEATURED_PROPERTIES": "BĐS nổi bật",
    "PROPERTIES_BY_LOCATION": "Bất động sản các khu vực",
    "UPDATED": "Cập nhật",
    "SEND_FEEDBACK": "Gửi phản hồi",
    "YOUR_EMAIL": "Địa chỉ email của bạn",
    "MESSAGE": "Lời nhắn",
    "BANNER_TITLE_1": "Bạn đang có nhu cầu",
    "BANNER_TITLE_2": "cần bán/cho thuê BĐS?",
    "MONDAY": "Thứ 2",
    "SATURDAY": "Thứ 7",
    "RESET": "Đặt lại",
    "APPLY": "Áp dụng",
    "TO": "Từ",
    "FROM": "Đến",
    "BILLION": "Tỷ",
    "MILLION": "Tr",
    "PRICE_RANGE": "Khoảng giá",
    "SQUARE_FEET": "Diện tích",
    "FACADE": "Mặt tiền",
    "PROPERTY_TYPE": "Loại BĐS",
    "MORE": "Tiện ích",
    "USE_EXACT_NUMBERS": "Sử dụng số chính xác",
    "BEDROOM": "Phòng ngủ",
    "BATHROOM": "Phòng tắm",
    "RANDOM": "Bất kì",
    "ERROR_MESSAGE_1": "Xin lỗi, tìm kiếm không có kết quả!",
    "ERROR_MESSAGE_2": "Nội dung tìm kiếm của bạn chưa chính xác! Bạn vui lòng tìm kiếm lại thông tin khác hoặc lựa chọn gợi ý từ HOALAND",
    "ERROR_MESSAGE_3": "Số điện thoại không hợp lệ",
    "ERROR_MESSAGE_4": "Email không hợp lệ",
    "ERROR_MESSAGE_5": "yêu cầu tối thiểu 6 ký tự",
    "ERROR_MESSAGE_6": "Xin lỗi, có vẻ trang này không tồn tại",
    "ERROR_MESSAGE_7": "Vui lòng quay trở lại trang chủ",
    "NEXT_PAGE": "Trang tiếp",
    "PREV_PAGE": "Trang trước",
    "CONTACT_MESSAGE": "Hoặc để lại thông tin tư vấn viên sẽ liên hệ với bạn",
    "SHARE": "Chia sẻ",
    "OVERVIEW": "Tổng quan",
    "SIMILAR_PROPERTIES": "BĐS tương tự",
    "NEARBY_PROPERTIES": "BĐS gần đây",
    "LATEST": "Mới nhất",
    "OLDEST": "Cũ nhất",
    "HIGH_TO_LOW_PRICE": "Giá cao đến thấp",
    "LOW_TO_HIGH_PRICE": "Giá thấp đến cao",
    "MOST_VIEWED": "Xem nhiều nhất",
    "INTRODUCTION": "Giới thiệu",
    "INTRODUCTION_CONTENT_1": "Công ty cổ phần HOALAND là một trong những công ty tư vấn BĐS hàng đầu tại Việt Nam trong lĩnh vực cung cấp dịch vụ bán, cho thuê các loại hình BĐS như văn phòng, nhà mặt phố, liền kề, biệt thư, căn hộ… cho khách hàng.",
    "INTRODUCTION_CONTENT_2": "Hiện chúng tôi đang sở hữu thông tin độc quyền của rất nhiều BĐS, mặt bằng đẹp, giá cả phù hợp với yêu cầu đa dạng của khách hàng. Để đảm bảo tính chính xác của thông tin, độị ngũ chuyên viên, môi giới BĐS của chúng tôi thường xuyên xác minh và xác nhận thông tin về các BĐS của HOALAND một cách chính xác nhất.",
    "INTRODUCTION_CONTENT_3": "HOALAND đã chứng tỏ năng lưc, uy tín của công ty khi là đối tác của những tập đoàn và những công ty hàng đầu của Việt Nam như Vingroup, FPT, Thế Giới Di Động …. HOALAND là công cụ kết nối, hỗ trợ quảng bá tốt nhất cho người có nhu cầu cần bán, cần cho thuê BĐS…. Các thông tin thông qua chúng tôi sẽ đến với tất cả những người có nhu cầu về BĐS thật sự.",
    "INTRODUCTION_CONTENT_4": "Chúng tôi tin rằng HOALAND sẽ là điểm đến tin cậy cho tất cả người dân có nhu cầu nắm bắt thông tin về BĐS.",
    "INTRODUCTION_CONTENT_5": "Với Slogan 'Kết Nối Tận Tâm - Vươn Tầm Quốc Tế', HOALAND đặt mục tiêu trở thành công ty BĐS số một tại Việt Nam trong hoạt động bán và cho thuê BĐS đồng thời xác định chiến lược mở rộng cung cấp dịch vụ BĐS cho khách hàng tại các nước trong khu vực Đông Nam Á và trên toàn cầu.",
    "INTRODUCTION_CONTENT_6": "Xây dựng hệ sinh thái BĐS ứng dụng công nghệ giúp minh bạch hóa thị trường BĐS, giúp những khách hàng có nhu cầu mua bán BĐS có thể tiếp cận, tương tác với nhau một cách nhanh chóng, thuận lợi, an toàn thông thoáng HOALAND và các nhà môi giới tận tâm và chuyên nghiệp.",
    "VISION": "Tầm nhìn",
    "MISSION": "Sứ mệnh",
    "PROPERTY_NEAR": "BĐS gần bạn",
    "NO_PRODUCTS_FOUND": "Không tìm thấy sản phẩm",
    "NUMBER_OF_STOREYS": "Số tầng",
    "PAVEMENT_WIDTH": "Đường rộng",
    "LOGIN_SUCCESS": "Bạn đã đăng nhập thành công!",
    "LOGIN_FAILED": "SĐT hoặc Mật khẩu không đúng!",
    "FAVORITE_PROPERTY": "BĐS yêu thích",
    "PROPERTY": "BĐS",
    "HOUSE_DIRECTION": "Hướng nhà",
    "CLICK_TO_REMOVE": "Nhấn để bỏ khỏi danh sách BĐS yêu thích",
    "INVALID_VALUE": "Giá trị không hợp lệ",
    "IMAGE": "Hình ảnh",
    "MAP": "Bản đồ",
    "TERMS_OF_USE": "Điều khoản và sử dụng",
    "CLICK_ACCEPT": "Bằng việc nhấn 'Tạo tài khoản mới', bạn đã chấp nhận",
    "REGISTERED_SUCCESSFULLY": "Đăng ký thành công",
    "NOTIFICATION": "Thông báo",
    "PRODUCT_TYPE": {
        "VILLAS": "Biệt thự",
        "COLLECTIVE_APARTMENT": "Căn hộ tập thể",
        "CONDOS": "Căn hộ chung cư",
        "WAREHOUSE": "Kho xưởng",
        "ROWHOUSE": "Liền kề",
        "TOWNHOUSE": "Nhà mặt phố",
        "HOUSE_IN_ALLEY": "Nhà trong ngõ",
        "INDEPENDENT_HOUSING": "Nhà ở độc lập",
        "FARM_RESORT": "Trang trại, khu nghỉ dưỡng",
        "OFFICE": "Văn phòng",
        "LOTS_AND_LAND": "Đất phân lô, đất nền",
        "OTHERS": "Khác"
    },
    "CHOOSE_FORM_CONSULTATION": "Chọn hình thức tư vấn",
    "DIRECT_MEETING": "Gặp trực tiếp",
    "VIDEO_MEETING": "Qua video",
    "SELECT_DATETIME": "Chọn ngày giờ",
    "NEGOTIABLE": "Giá thoả thuận",
    "UNKNOWN": "Không xác định",
    "UPDATING": "Đang cập nhật",
    "MONTH": "Tháng",
    "NOTIFY_AGENT_CONTENT_1": "Tài khoản của Agent chưa được duyệt quyền xem các BĐS trên website.",
    "ANNOUNCE_MESSAGE_1": "Vui lòng liên hệ hotline",
    "ANNOUNCE_MESSAGE_2": "để yêu cầu trợ giúp thêm.",
    "THANKS_FOR_USE": "Cảm ơn quý khách đã tin tưởng và sử dụng dịch vụ Hoaland. Trân trọng!",
    "UNDERSTAND": "Tôi đã hiểu",
    "FORGOT_PW_TITLE": "Nhập số điện thoại đã đăng ký với HOALAND",
    "ABBR_SQUARE_FEET": "DT",
    "ABBR_PAVEMENT_WIDTH": "MT",
    "DIRECTION": {
        "EAST": "Đông",
        "WEST": "Tây",
        "SOUTH": "Nam",
        "NORTH": "Bắc",
        "SOUTH_EAST": "Đông Nam",
        "NORTH_EAST": "Đông Bắc",
        "SOUTH_WEST": "Tây Nam",
        "NORTH_WEST": "Tây Bắc",
        "NORTH_EAST_NORTH": "Đông Bắc - Bắc"
    },
    "ERROR_OCCURRED": "Đã xảy ra lỗi",
    "THANK_FOR_CONTACT": "Cảm ơn bạn đã liên hệ với chúng tôi",
    "THANKS_FOR_INTEREST": "Cảm ơn bạn đã quan tâm đến sản phẩm",
    "THANKS_FOR_FEEDBACK": "Cảm ơn bạn đã phản hồi cho chúng tôi",
    "ACCOUNT_WAITING_MESSAGE": "Chúc mừng tài khoản {{ value }} đã đăng ký thành công tài khoản tại Hoaland.",
    "ACCOUNT_WAITING_MESSAGE_1": "Hiện tại tài khoản đang được chờ phê duyệt, thời gian duyệt là từ 1 giờ cho đến 12 giờ làm việc sau khi đăng ký.",
    "FOOTER_MESSAGE": "Chúng tôi sẽ gửi những tin tức BĐS mới nhất cho bạn!",
    "RESET_PW_SUCCESSFUL": "Khôi phục mật khẩu thành công",
    "COPIED": "Đã sao chép",
    "FORGOT_PASSWORD_MESSAGE": "Vui lòng nhập mã OTP dưới đây để xác minh số điện thoại của bạn",
    "OTP_MESSAGE": "Vui lòng nhập mã OTP được gửi vào email %d của bạn",
    "POST_MANAGER": "Tin đã đăng",
    "PROFILE": "Hồ sơ",
    "CHANGE_PASSWORD": "Đổi mật khẩu",
    "USER_PROFILE_SUB_HEADING": "Quản lý thông tin hồ sơ để bảo mật tài khoản",
    "CHANGE_PASSWORD_SUB_HEADING": "Để bảo mật tài khoản, vui lòng không chia sẻ mật khẩu cho người khác",
    "HELLO": "Xin chào",
    "WISH_SYNONYMS": "Chúc bạn một ngày tốt lành!",
    "LANGUAGE": "Ngôn ngữ",
    "WELCOME": "Chào mừng bạn đến với HOALAND",
    "REQUEST_TO_APPLY": "Liên hệ tư vấn",
    "FILTER": "Bộ lọc",
    "SORTED_BY": "Sắp xếp theo",
    "SEARCH_HISTORY": "Lịch sử tìm kiếm",
    "SUGGESTED_AREAS": "Khu vực đề xuất",
    "SUGGESTED_PROPERTIES": "Bất động sản đề xuất",
    "MANY_REQUEST_NOTIFICATION": "Bạn đã quá lượt truy cập cho phép miễn phí mỗi ngày.",
    "MANY_REQUEST_NOTIFICATION_1": "Vui lòng liên hệ CSKH của Hoaland để thêm lượt truy cập."
}
