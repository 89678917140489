import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { API_URL, KEY } from "@constants/backend-url.constants";
import { ERROR_MESSAGE } from "@constants/common";
import { VerifyOTPModel } from "@models/auth/auth.model";
import { AuthService } from "@services/auth/auth.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { ToastrService } from "ngx-toastr";
import { catchError, Subject, takeUntil, tap } from "rxjs";
import { ResetPwModalComponent } from "../reset-pw-modal/reset-pw-modal.component";
import { UserModel } from "@models/user/user.model";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-otp-register-modal",
    templateUrl: "./otp-register-modal.component.html",
})
export class OtpRegisterModalComponent {
    public ERROR_MESSAGE = ERROR_MESSAGE;
    private subscription$ = new Subject();
    public disabledBtn: boolean;
    public countDown: number;
    public email: string = this.storageService.get(KEY.USER_LOGIN)?.email;
    public phone: string = this.storageService.get(KEY.USER_LOGIN)?.phone;
    private readonly TIME_DEFAULT_ALLOW_RESEND = 10;

    constructor(
        private dialogRef: MatDialogRef<ResetPwModalComponent>,
        private storageService: TokenStorageService,
        private authService: AuthService,
        private toastrService: ToastrService,
        private router: Router
    ) {}

    public onCloseModal() {
        this.dialogRef.close();
    }

    public onResendOTP() {
        this.delayTimerClickResend();
        this.authService
            .resendOTP({ phone: this.phone, email: this.email })
            .subscribe((_) => {});
    }

    public completedEntered(otp: string) {
        const user = this.storageService.get(KEY.USER_LOGIN);
        const payload: VerifyOTPModel = {
            otp,
            username: user.username,
        };

        this.authService
            .verifyOTP(payload)
            .pipe(
                takeUntil(this.subscription$),
                catchError(({ error }) => {
                    const { errors } = error;
                    this.showError(errors);
                    return error;
                })
            )
            .subscribe((_) => {
                this.toastrService.success("Đăng ký thành công", "", {
                    toastClass: "toast-customize ngx-toastr",
                });
                this.getCurrentUserLogin();
            });
    }

    private showError(errors) {
        errors?.forEach(({ code }) => {
            const message =
                this.ERROR_MESSAGE[code] || this.ERROR_MESSAGE.UNDEFINED;
            this.toastrService.error(message);
        });
    }

    private resetCountDown() {
        this.countDown = this.TIME_DEFAULT_ALLOW_RESEND;
    }

    private setStatusButtonResend(status: boolean) {
        this.disabledBtn = status;
    }

    private runCounter(): void {
        const delay = 1000;
        const timer = setInterval(() => {
            if (this.countDown == 0) {
                clearInterval(timer);
                this.disabledBtn = false;
            } else {
                this.countDown = this.countDown - 1;
            }
        }, delay);
    }

    private getCurrentUserLogin(): void {
        const currentUser$ = this.authService.currentUserInfo(
            API_URL.getCurrentUserLogin
        );
        currentUser$
            .pipe(tap((data) => this.updateUserInfo(data)))
            .subscribe(() => {
                this.storageService.delete(KEY.REDIRECT_URL);
                window.location.reload();
                this.router
                    .navigate([APP_PAGES.DEFAULT], {
                        replaceUrl: true,
                    })
                    .then();
            });
    }

    private updateUserInfo(data: UserModel) {
        // Save role-permissions into localstorage
        this.storageService.set(KEY.USER_LOGIN, { ...data });
        // Save role-permissions into localstorage
        this.authService.setUserInfo({ ...data });
        // SET LOCAL STORE  PROPERTY CARE
        this.storageService.set(KEY.SEARCH_HISTORY, []);
    }

    private delayTimerClickResend(): void {
        // reset đếm thời gian mặc định
        this.resetCountDown();
        // reset disabled nút resend.
        this.setStatusButtonResend(true);
        // chạy lại bộ đếm.
        this.runCounter();
    }

    ngOnDestroy(): void {
        this.subscription$.next({});
        this.subscription$.complete();
    }
}
