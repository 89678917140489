import { Component, OnInit } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";

@Component({
  selector: "app-widget-info-real-estate",
  templateUrl: "./widget-info-real-estate.component.html",
  styleUrls: ["./widget-info-real-estate.component.scss"],
})
export class WidgetInfoRealEstateComponent implements OnInit {
  public WAITING_APPROVE_STATUS: (string | number)[] = ["01", "03", "11"];
  public APPROVED_SELL_STATUS: (string | number)[] = ["02"];
  public PAGE_DEFAULT = 0;
  public SIZE_DEFAULT = 100;
  public lands: RealEstate[] = [];
  public approvedSellLand: RealEstate[] = [];
  public waitingApproveLand: RealEstate[] = [];

  constructor(private landService: HttpRealEstateService) {}

  ngOnInit() {
    // this.getAllProducts();
  }

  public getAllProducts(page?: number) {
    const currentPage = page ? page : this.PAGE_DEFAULT;
    this.landService
      .getProducts({
        page: currentPage,
        limit: this.SIZE_DEFAULT,
      })
      .then((res) => {
        this.lands = this.lands.concat(...res.data);
        if (this.lands.length < res.totalItems) {
          this.getAllProducts(currentPage + 1);
        }

        this.getApprovedSellLand();
        this.getWaitingApproveLand();
      });
  }

  public getApprovedSellLand() {
    this.approvedSellLand = this.lands.filter((land: RealEstate) =>
      this.APPROVED_SELL_STATUS.includes(land.status)
    );
  }

  public getWaitingApproveLand() {
    this.waitingApproveLand = this.lands.filter((land: RealEstate) =>
      this.WAITING_APPROVE_STATUS.includes(land.status)
    );
  }
}
