import {
    Component,
    EventEmitter,
    Inject,
    Output,
    PLATFORM_ID,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_PAGES } from "@constants/app-url.constants";
import { ScrollToTop } from "@helpers/scroll-to-top";
import { SearchRealEstateParams } from "@models/land/real-estate.model";
import { UserModel } from "@models/user/user.model";
import { AuthModalComponent } from "@pages/auth/auth-modal/auth-modal.component";
import { AuthService } from "@services/auth/auth.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-header",
    templateUrl: "./app-header.component.html",
    styleUrls: ["./app-header.component.scss"],
})
export class AppHeaderComponent {
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild("horizontalMenu") horizontalMenu: any;

    readonly MENU_DEFAULT = 11; //Home
    public userInfo$: Observable<UserModel> = this.authService.getUserInfo();
    public searchLandParams: FormGroup = this.fb.group(
        new SearchRealEstateParams({})
    );

    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    public logout() {
        const removeKeys = [
            "JWT_TOKEN",
            "JWT_REFRESH_TOKEN",
            "USER_LOGIN",
            "PROPERTY_FOLLOW",
            "REDIRECT_URL",
        ];
        removeKeys.forEach((k) => localStorage.removeItem(k));
        window.location.reload();
    }

    public resetPage() {
        if (this.horizontalMenu)
            this.horizontalMenu.menuSelected = this.MENU_DEFAULT;
        ScrollToTop(this.platformId);
    }

    public handleChangeAddress(params: any): void {
        this.searchLandParams.patchValue({
            page: 0,
        });
        this.router.navigate([APP_PAGES.SEARCH], {
            relativeTo: this.activeRoute,
            queryParams: { address: params?.address },
        }).then();
    }

    public onOpenRegisterModal() {
        const modalRef = this.dialog.open(AuthModalComponent, {
            panelClass: "modal-panel",
        });
        modalRef.componentInstance.tab = 1;
    }
}
