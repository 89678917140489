import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "lineBreak",
})
export class LineBreakPipe implements PipeTransform {
  transform(value: any): any {
    const patternRegexp = /(?:\r\n|\r|\n)/g;

    return value?.replaceAll(patternRegexp, "<br>");
  }
}
