import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";
import { DetectVideoFile } from "@helpers/detect-media-type";
import { ImgSizeThumbnailPipe } from "@pipes/image/image-size-thumbnail.pipe";
import { JWT_TOKEN, LAND_PURPOSE } from "@constants/common";
import { CompareRealEstateService } from "@services/common/compare-real-estate.service";
import { APP_PAGES } from "@constants/app-url.constants";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { KEY } from "@constants/backend-url.constants";
import { ACCOUNT_TYPE, USER_STATUS } from "@constants/business.constants";
import { AuthModalComponent } from "@pages/auth/auth-modal/auth-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { NotifyForAgentModalComponent } from "../notify-for-agent/notify-for-agent.component";
import { AccountWatingApprovedComponent } from "../account-wating-approved/account-wating-approved.component";
import { ShowNotification } from "@utils/helper";

@Component({
    selector: "app-real-estate-item",
    templateUrl: "./land-item.component.html",
    styleUrls: ["./land-item.component.scss"],
})
export class LandItemComponent implements OnInit, OnChanges {
    @Input() realEstate: RealEstate;
    @Input() viewType: string = "grid";
    @Input() viewColChanged: number;
    @Input() fullWidthPage: boolean = true;
    @Input() hiddenFooter: boolean;
    @Output() itemSelected = new EventEmitter();

    public column: number = 4;
    public readonly LAND_PURPOSE = LAND_PURPOSE;
    public readonly APP_PAGES_ROUTER = APP_PAGES;

    public mediaUrl: string;
    public isVideo: boolean;

    constructor(
        public thumbnailPipe: ImgSizeThumbnailPipe,
        private compareService: CompareRealEstateService,
        private storageService: TokenStorageService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        // Get thumbnail image 380x380px.
        this.mediaUrl = this.thumbnailPipe.transform(
            this.realEstate.photos[0]?.url,
        );
        this.isVideo = DetectVideoFile(this.realEstate.photos[0]?.url);
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes.viewColChanged) {
            this.getColumnCount(changes.viewColChanged.currentValue);
        }
    }

    public getColumnCount(value) {
        if (value == 25) {
            this.column = 4;
        } else if (value == 33.3) {
            this.column = 3;
        } else if (value == 50) {
            this.column = 2;
        } else {
            this.column = 1;
        }
    }

    public onItemClick() {
        this.itemSelected.emit(this.realEstate);
    }

    public addToCompare(event: Event) {
        event.stopImmediatePropagation();
        const user = this.storageService.get(KEY.USER_LOGIN);
        const isActiveAccount = Boolean(user?.status === USER_STATUS.ACTIVE);
        const hasToken = this.storageService.get(JWT_TOKEN);

        const showDialog =
            (!isActiveAccount && user) || user?.type === ACCOUNT_TYPE.AGENT;

        const hasPermissionAddCompare =
            hasToken && isActiveAccount && user && user.type !== ACCOUNT_TYPE.AGENT;

        if (!hasToken) {
            setTimeout(() => {
                this.dialog.open(AuthModalComponent, {
                    panelClass: "modal-panel",
                });
            }, 0);
            return;
        }

        if (hasPermissionAddCompare) {
            this.compareService.add(this.realEstate);
        } else if (showDialog) {
            const component =
                user?.type === ACCOUNT_TYPE.AGENT
                    ? NotifyForAgentModalComponent
                    : AccountWatingApprovedComponent;

            ShowNotification(this.dialog, component, {
                name: user?.username,
            });
        }
    }

    protected readonly Math = Math;
    protected readonly APP_PAGES = APP_PAGES;
}
