import { Component } from "@angular/core";

@Component({
    selector: "app-lang",
    templateUrl: "./lang.component.html",
    styleUrls: ["./lang.component.scss"],
})
export class LangComponent {
    // public langName = "";
    // public countries = {
    //     en: "English",
    //     vn: "Tiếng Việt",
    // };
    // ngOnInit(): void {
    //   localStorage.getItem("LANGUAGE") || "en";
    //   if (this.translate.currentLang === "en") {
    //     this.langName = this.countries.en;
    //   } else this.langName = this.countries.vn;
    // }
    //
    // public changeLang(lang: string): void {
    //   localStorage.setItem("LANGUAGE", lang);
    //   this.translate.use(lang);
    //   this.langName = this.countries[lang];
    // }
}
