export const KEY = {
    USER_LOGIN: "USER_LOGIN",
    RESET_PWD: "reset_pwd",
    APP_VERSION: "APP_VERSION",
    POLYGON: "POLYGON",
    SEARCH_HISTORY: "SEARCH_HISTORY",
    COMPARE_HISTORY: "COMPARE_HISTORY",
    DETAIL_HISTORY: "DETAIL_HISTORY",
    REDIRECT_URL: "REDIRECT_URL",
    USER_PERMISSIONS: "USER_PERMISSIONS"
};

const MODULES = {
    CRM: "crm",
    LAND_MANAGEMENT: "land-management",
    AUTH: "auth",
};

export const PATH_API_AUTH = {
    REGISTER: "auth/register/account",
    CURRENT_USER_LOGIN: "auth/login-user",
    LOGIN: "auth/login",
    REFRESH_TOKEN: "auth/refresh-token",
    LOGOUT: "auth/logout",
    VERIFY_OTP: "auth/register/verify-reg-otp",
    FORGOT_PWD: "auth/register/forgot-pw",
    VERIFY_OTP_FORGOT_PWD: "auth/register/verify-fpw-otp",
    CHANGE_PWD: "auth/register/change-pw",
};

export const PATH_REAL_ESTATE = {
    SEARCH: "land-management/search",
    STATS_IN_AREA: "land-management/analytics/stats-by-area",
    IN_FRAME_MAP: "land-management/search/point-in-bound",
    IN_POLYGON: "land-management/search/in-polygon",
};

export const PATH_MESSAGE = {
    REGISTER_NOTIFICATION: "message/notifications/register"
}

export const API_URL = {
    REGISTER: "auth/register/account",
    getCurrentUserLogin: "auth/login-user",
    LOGIN: "auth/login",
    REFRESH_TOKEN: "auth/refresh-token",
    LOGOUT: "auth/logout",
    verifyOTP: "auth/register/verify-reg-otp",
    RESEND_OTP: "auth/register/resend-otp",
    forgotPwd: "auth/register/forgot-pw",
    verifyOTPForgotPwd: "auth/register/verify-fpw-otp",
    changePwd: "auth/register/change-pw",
    USER_MANAGEMENT: "user-management/customers",
    HISTORY_SEARCH: "real-estate/history-search",
    CRM: "crm",

    LIST_REAL_ESTATE_V2: "land-management/search/v2",
    LIST_REAL_ESTATE: "land-management/search",
    STATS_IN_AREA: "land-management/analytics/stats-by-area",
    IN_FRAME_MAP: "land-management/search/point-in-bound",

    allCategory: "auth/category/getAll",

    product_detail: "land-management/land",

    // Favorite RealEstate
    FAVORITE_REAL_ESTATE: "land-management/lands/favorite",
    ADD_REAL_ESTATE_FAVORITE: "land-management/follow",

    // CRM
    SUBMIT_CONTACT: `${MODULES.CRM}/submit-contact-ticket`,

    // RESOURCES
    RESOURCES: {
        REGION_SUGGESTION: "resources/regions/suggestions",
        REGION_BOUNDARIES: "resources/regions/boundaries",
    }
};
