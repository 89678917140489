import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { OverlayContainer } from "@angular/cdk/overlay";

import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";

import { ToastrModule } from "ngx-toastr";
import { DBConfig, NgxIndexedDBModule } from "ngx-indexed-db";

import { FlexLayoutModule } from "@angular/flex-layout";
import { STORAGE_DB_KEY, STORAGE_LOCATION_KEY } from "@constants/common";
import { ErrorInterceptor } from "@utils/error.interceptor";
import { AppGetTokenInterceptor } from "@utils/app-get-token.interceptor";
import { EnsureHttpsInterceptor } from "@utils/ensure-https.interceptor";
import { CustomOverlayContainer } from "@utils/custom-overlay-container";
import { PipesModule } from "@pipes/pipes.module";
import { HorizontalMenuComponent } from "app/public/modules/app-header/horizontal-menu/horizontal-menu.component";
import { LangComponent } from "app/public/modules/language/lang/lang.component";
import { NotFoundComponent } from "@pages/not-found/not-found.component";
import { Http429Interceptor } from "@utils/429-http.interceptor";
import { AppInterceptor } from "@utils/app.interceptor";
import { JwtTokenService } from "@services/jwt-token.service";
import { AuthService } from "@services/auth/auth.service";
import { CategoryService } from "@services/category/category.service";
import { OtpGuard } from "@guard/otp.guard";
import { EventBusService } from "@services/common/event-bus.service";
import { environment } from "environments/environment";
import { AgmCoreModule } from "@agm/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { initializeApp } from "firebase/app";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppSettings } from "app/app.settings";
import { InputSearchSuggestModule } from "app/public/modules/search-suggestion/input-search-suggest.module";
import { RealEstateInfoModule } from "app/public/modules/real-estate-info/real-estate-info.module";
import { AppFooterModule } from "app/public/modules/app-footer/app-footer.module";
import { ContactModule } from "app/public/modules/contact/contact.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FavoriteRealEstateModule } from "app/public/modules/favorite-real-estate/favorite-real-estate.module";
import { MatListModule } from "@angular/material/list";
import { DirectivesModule } from "@directives/directives.module";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { UserMenuModule } from "app/public/modules/user-menu/user-menu.module";
import { HomeModule } from "@pages/home/home.module";
import { LogoModule } from "app/public/modules/logo/logo.module";
import { AuthModule } from "@pages/auth/auth.module";
import { MatTabsModule } from "@angular/material/tabs";
import { HeaderLayoutModule } from "app/public/modules/app-header/header-layout.module";
import { CompareRealEstatesModule } from "./public/modules/compare-realestate/compare-real-estates.module";

initializeApp(environment.firebaseConfig);

const dbConfig: DBConfig = {
    name: "hoaland",
    version: 15,
    objectStoresMeta: [
        {
            store: STORAGE_DB_KEY.districts,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.towns,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.cities,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.land_type,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.direction,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.floorMaterial,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.street,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.parking,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.statusFurniture,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.juridical,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_DB_KEY.cate_street,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_LOCATION_KEY.cities,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_LOCATION_KEY.districts,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
        {
            store: STORAGE_LOCATION_KEY.towns,
            storeConfig: { keyPath: "ids", autoIncrement: true },
            storeSchema: [
                { name: "cKey", keypath: "cKey", options: { unique: false } },
                {
                    name: "cValue",
                    keypath: "cValue",
                    options: { unique: true },
                },
                {
                    name: "categoryCode",
                    keypath: "categoryCode",
                    options: { unique: false },
                },
                {
                    name: "refCode",
                    keypath: "refCode",
                    options: { unique: false },
                },
                {
                    name: "refValue",
                    keypath: "refValue",
                    options: { unique: false },
                },
            ],
        },
    ],
};

const VENDOR_MODULES = [
    MatSidenavModule,
    MatTabsModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    FormsModule,
    PerfectScrollbarModule,
    DirectivesModule,
    ToastrModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    FlexLayoutModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ServiceWorkerModule.register("ngsw-config.js", {
        enabled: environment.production,
        registrationStrategy: "registerImmediately",
    }),
    HttpClientModule,
    AgmCoreModule.forRoot({
        apiKey: environment.googleApiKey,
        language: "vi",
        libraries: ["places"],
    }),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
];

const INTERNAL_MODULES = [
    HomeModule,
    AuthModule,
    LogoModule,
    UserMenuModule,
    FavoriteRealEstateModule,
    AppFooterModule,
    ContactModule,
    RealEstateInfoModule,
    InputSearchSuggestModule,
    CompareRealEstatesModule,
    AppRoutingModule,
    PipesModule,
    HeaderLayoutModule,
];

const COMPONENTS = [
    AppComponent,
    NotFoundComponent,
    LangComponent,
];

@NgModule({
    declarations: [...COMPONENTS],
    imports: [...VENDOR_MODULES, ...INTERNAL_MODULES],
    providers: [
        AuthService,
        AppSettings,
        CategoryService,
        EventBusService,
        JwtTokenService,
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EnsureHttpsInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppGetTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Http429Interceptor,
            multi: true,
        },
        OtpGuard,
    ],
    bootstrap: [AppComponent],
    exports: [
        HorizontalMenuComponent,
    ],
})
export class AppModule {
}
