import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "app/guard/auth.guard";
import { NotFoundComponent } from "@pages/not-found/not-found.component";
import { HomeLayoutContainerComponent } from "@pages/home/home-layout-container/home-layout-container.component";
import { APP_PAGES } from "@constants/app-url.constants";
import { PreventUserUnactiveGuard } from "@guard/prevent-user-unactive.guard";

export const routes: Routes = [
    {
        path: "",
        component: HomeLayoutContainerComponent,
        data: ["CUSTOMER", "ADMIN"],
    },
    {
        path: APP_PAGES.FAVORITE_REAL_ESTATE,
        canActivate: [AuthGuard, PreventUserUnactiveGuard],
        loadChildren: () =>
            import("./pages/properties-more/properties-more.module").then(
                (m) => m.PropertiesMoreModule,
            ),
    },
    {
        path: APP_PAGES.ABOUT_US,
        canActivate: [PreventUserUnactiveGuard],
        loadChildren: () =>
            import("./pages/about/about.module").then((m) => m.AboutModule),
    },
    {
        path: APP_PAGES.SEARCH,
        canActivate: [AuthGuard, PreventUserUnactiveGuard],
        loadChildren: () =>
            import("./pages/search/search.module").then((m) => m.SearchModule),
    },
    {
        path: APP_PAGES.USER,
        canActivate: [PreventUserUnactiveGuard],
        loadChildren: () =>
            import("app/public/modules/user-menu/user-menu.module").then(
                (m) => m.UserMenuModule,
            ),
    },
    {
        path: APP_PAGES.COMPARE_REAL_ESTATE,
        canActivate: [AuthGuard, PreventUserUnactiveGuard],
        loadChildren: () =>
            import(
                "./pages/compare-real-estates/compare-real-estates.module"
                ).then((m) => m.CompareRealEstatesModule),
    },
    { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            relativeLinkResolution: "legacy",
            initialNavigation: "enabled", // for one load page, without reload
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
