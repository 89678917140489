import { Component, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { KEY } from "@constants/backend-url.constants";
import { VerifyOTPModel } from "@models/auth/auth.model";
import { AuthService } from "@services/auth/auth.service";
import { NotifyService } from "@services/common/notify.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Subject, takeUntil } from "rxjs";
import { catchError } from "rxjs/operators";
import { NewPasswordModalComponent } from "../new-password-modal/new-password-modal.component";
import { ERROR_MESSAGE } from "@constants/common";

@Component({
    selector: "app-otp-reset-pw-modal",
    templateUrl: "./otp-reset-pw-modal.component.html",
    styleUrls: ["./otp-reset-pw-modal.component.scss"],
})
export class OtpResetPwModalComponent implements OnDestroy {
    public ERROR_MESSAGE = ERROR_MESSAGE;
    private subscription$ = new Subject();
    public disabledBtn: boolean;
    public countDown: number;
    public email: string = this.storageService.get(KEY.RESET_PWD)?.email;
    public phone: string = this.storageService.get(KEY.RESET_PWD)?.phone;
    private readonly TIME_DEFAULT_ALLOW_RESEND = 10;

    constructor(
        public fb: FormBuilder,
        private authService: AuthService,
        private notify: NotifyService,
        private storageService: TokenStorageService,
        private dialogRef: MatDialogRef<OtpResetPwModalComponent>,
        private dialog: MatDialog
    ) {}

    public onCloseRefModal() {
        this.dialogRef.close();
    }

    public onEmitOtp(otp: string): void {
        if (otp) {
            const phone = this.phone;
            const payload: VerifyOTPModel = {
                otp,
                username: phone,
            };
            this.authService
                .verifyOTPForgotPwd(payload)
                .pipe(
                    takeUntil(this.subscription$),
                    catchError(({ error }) => {
                        this.showError(error);
                        return error;
                    })
                )
                .subscribe((_) => {
                    this.dialog.open(NewPasswordModalComponent, {
                        panelClass: "modal-panel",
                        disableClose: true,
                    });
                    this.onCloseRefModal();
                });
        }
    }

    public onResendOTP() {
        this.delayTimerClickResend();
        this.authService
            .resendOTP({ phone: this.phone, email: this.email })
            .pipe(
                catchError((err) => {
                    return err;
                })
            )
            .subscribe((_) => {});
    }

    private delayTimerClickResend(): void {
        // reset đếm thời gian mặc định
        this.resetCountDown();
        // reset disabled nút resend.
        this.setStatusButtonResend(true);
        // chạy lại bộ đếm.
        this.runCounter();
    }

    private resetCountDown() {
        this.countDown = this.TIME_DEFAULT_ALLOW_RESEND;
    }

    private runCounter(): void {
        const delay = 1000;
        const timer = setInterval(() => {
            if (this.countDown == 0) {
                clearInterval(timer);
                this.disabledBtn = false;
            } else {
                this.countDown = this.countDown - 1;
            }
        }, delay);
    }

    private setStatusButtonResend(status: boolean) {
        this.disabledBtn = status;
    }

    private showError(error) {
        const { errors } = error;
        errors?.forEach(({ code }) => {
            this.notify.waring(this.ERROR_MESSAGE[code]);
        });
        return error;
    }

    ngOnDestroy(): void {
        this.subscription$.next(null);
        this.subscription$.complete();
    }
}
