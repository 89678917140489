import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { getAddressType } from "@utils/helper";
import { ActivatedRoute } from "@angular/router";

const SETTING_ZOOM = {
  WARD: 14,
  DISTRICT: 12,
  CITY: 10,
  OTHER: 18,
};

@Injectable({
  providedIn: "root",
})
export class PolygonAreaService {
  private polygons$ = new BehaviorSubject<any[]>([]);
  private location$ = new BehaviorSubject<any>(null);
  private zoom$ = new BehaviorSubject<any>(null);

  constructor(
    private activatedRouter: ActivatedRoute,
  ) {
    this.observeAddressRouteChanged();
  }

  // For polygons
  set setAreaPolygon(data: any) {
    this.polygons$.next(data);
  }

  // For location
  set setLocation(data: { lat: number; lng: number }) {
    this.location$.next(data);
  }

  // For zoom
  set setZoom(data: any) {
    const type = getAddressType(data);
    this.zoom$.next(SETTING_ZOOM[type]);
  }

  public areaPolygon(): Observable<any> {
    return this.polygons$.asObservable();
  }

  public onLocation(): Observable<any> {
    return this.location$.asObservable();
  }

  public onZoom(): Observable<any> {
    return this.zoom$.asObservable();
  }

  private observeAddressRouteChanged(): void {
    this.activatedRouter.queryParams.pipe().subscribe((data) => {
      const address = data["address"];
      if (!address) this.setAreaPolygon = [];
    });
  }
}
