import { APP_PAGES } from "@constants/app-url.constants";

export const JWT_TOKEN = "JWT_TOKEN";
export const JWT_RESET_PWD = "JWT_RESET_PWD";
export const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";

export const LAND_PURPOSE = {
    FOR_SELL: "1",
    FOR_RENT: "2",
};

export const STORAGE_DB_KEY = {
    land_type: "landType",
    street: "street",
    districts: "districts",
    cities: "cities",
    towns: "towns",
    direction: "direction",
    floorMaterial: "floorMaterial",
    parking: "parking",
    statusFurniture: "statusFurniture",
    cate_street: "cateStreet",
    juridical: "juridical",
};

export const STORAGE_LOCATION_KEY = {
    districts: "locationDistricts",
    cities: "locationCities",
    towns: "locationTowns",
};

export const VIDEO_FILE_TYPE = [
    ".3gp",
    ".mkv",
    ".mp4",
    ".ts",
    ".webm",
    ".ogg",
    ".MPEG-4",
    ".MPEG",
    ".M4V",
    ".H.264",
    ".HEVC",
];
export const IMAGE_FILE_TYPE = [
    ".bmp",
    ".gif",
    ".png",
    ".jpeg",
    ".jpg",
    ".png",
    ".webp",
];

export const CONTACT_TYPE = {
    BOOK_ORDER: 1, //Đặt lịch
    CONSULTANT: 2, // Tư vấn,
    OTHER: 0, // chưa định nghĩa,
};

export const BOOK_ORDER_TYPE = {
    VIDEO: 2, //qua video
    DIRECT: 1, // truc tiep
};

export const DEFINE_TYPE_ADDRESS = {
    WARD: "WARD",
    DISTRICT: "DISTRICT",
    CITY: "CITY",
    OTHER: "OTHER",
};

export const TYPE_REAL_ESTATE = [
    { id: "2", title: "Biệt thự" },
    { id: "4", title: "Căn hộ tập thể" },
    { id: "6", title: "Căn hộ chung cư" },
    { id: "8", title: "Kho xưởng" },
    { id: "12", title: "Liền kề" },
    { id: "3", title: "Nhà mặt phố" },
    { id: "7", title: "Nhà trong ngõ" },
    { id: "13", title: "Nhà ở độc lập" },
    { id: "11", title: "Trang trại, khu nghỉ dưỡng" },
    { id: "1", title: "Văn phòng" },
    { id: "9", title: "Đất phân lô, đất nền" },
    { id: "10", title: "Khác" },
];

export const TYPE_DIRECTION = [
    { cKey: "4", cValue: "Bắc" },
    { cKey: "3", cValue: "Nam" },
    { cKey: "2", cValue: "Tây" },
    { cKey: "8", cValue: "Tây Bắc" },
    { cKey: "7", cValue: "Tây Nam" },
    { cKey: "1", cValue: "Đông" },
    { cKey: "6", cValue: "Đông Bắc" },
    { cKey: "10", cValue: "Đông Bắc - Bắc" },
    { cKey: "5", cValue: "Đông Nam" },
];

export const ERROR_MESSAGE = {
    USERNAME_EXIST: "Người dùng đã tồn tại",
    PHONE_EXIST: "Số điện thoại đã tồn tại",
    EMAIL_FORMAT: "Email không tồn tại",
    EMAIL_EXIST: "Email đã tồn tại",
    PHONE_NOT_EXIST: "Số điện thoại chưa đăng ký",
    OTP_NOT_MATCHED: "Mã OTP không đúng",
    UNDEFINED: "Lỗi không xác định",
    INVALID_EMAIL: "Thông tin email không đúng",
};

export const FURNITURE_SECTION = [
    {
        title: "Tình trạng",
        key: "furniture",
        value: "Cơ bản",
    },
    {
        title: "Phòng ngủ",
        key: "bedrooms",
        value: "",
    },
    {
        title: "Phòng vệ sinh",
        key: "toilets",
        value: "",
    },
    {
        title: "Số tầng",
        key: "floors",
        value: "",
    },
    {
        title: "Nhà bếp",
        key: "kitchens",
        value: "",
    },
    {
        title: "Phòng ăn",
        key: "dinningRooms",
        value: "",
    },
    {
        title: "Phòng khách",
        key: "livingRooms",
        value: "",
    },
    {
        title: "Điều hoà",
        key: "airConditioners",
        value: "",
    },
    {
        title: "Nóng lạnh",
        key: "heaters",
        value: "",
    },
    {
        title: "Thang máy",
        key: "elevators",
        value: "",
    },
    {
        title: "Tầng hầm",
        key: "basement",
        value: "",
    },
    {
        title: "Sàn",
        key: "floorMaterial",
        value: "",
    },
];

export const MORE_INFO_SECTION = [
    {
        title: "Loại đường",
        key: "streetType",
        value: "",
    },
    {
        title: "Năm xây dựng",
        key: "constructYear",
        value: "",
    },
    {
        title: "Pháp lý",
        key: "",
        value: "",
    },
];

export const AREA_INFO_SECTION = [
    {
        title: "Diện tích đất",
        key: "landArea",
        value: "",
    },
    {
        title: "Diện tích xây dựng",
        key: "buildArea",
        value: "",
    },
    {
        title: "Chiều sâu",
        key: "length",
        value: "",
    },
    {
        title: "Chiều sâu thông thuỷ",
        key: "clearanceLength",
        value: "",
    },
    {
        title: "Dài mặt sau",
        key: "backWidth",
        value: "",
    },
    {
        title: "Dài mặt sau thông thuỷ",
        key: "clearanceBw",
        value: "",
    },
    {
        title: "Mặt tiền thông thuỷ",
        key: "clearanceFw",
        value: "",
    },
];

export const EXTERIOR_INFO_SECTION = [
    {
        title: "Cửa sổ",
        key: "windows",
        value: "",
    },
    {
        title: "Ban công",
        key: "balcony",
        value: "",
    },
    {
        title: "Hướng ban công",
        key: "balconyDirection",
        value: "",
    },
    {
        title: "Sân",
        key: "yard",
        value: "",
    },
    {
        title: "Vườn",
        key: "garden",
        value: "",
    },
];

export const CAR_PARK_INFO_SECTION = [
    {
        title: "Đặc điểm \n" + "khu vực đỗ xe",
        key: "parking",
        value: "",
    },
    {
        title: "Khoảng cách từ nhà\n" + "đến bãi đỗ xe (m)",
        key: "parkingDistance",
        value: "",
    },
];

export const CLIENTS_IMG = [
    "assets/images/partner/cellphones.png",
    "assets/images/partner/pantino.png",
    "assets/images/partner/vietcombank.png",
    "assets/images/partner/tgdd.png",
    "assets/images/partner/some-by-mi.png",
    "assets/images/partner/yody.png",
    "assets/images/partner/kids-plaza.png",
    "assets/images/partner/pharmacity.png",
    "assets/images/partner/tp.png",
    "assets/images/partner/bobo-mart.png",
    "assets/images/partner/vietinbank.png",
    "assets/images/partner/pnj.png",
    "assets/images/partner/some-by-mi.png",
    "assets/images/partner/Hnoss.png",
    "assets/images/partner/con-cung.png",
];

export const DEFINED_FILTER = [
    {
        value: "LATEST",
        orderColumns: "updated",
        orderDirects: "DESC",
    },
    {
        value: "OLDEST",
        orderColumns: "updated",
        orderDirects: "ASC",
    },
    {
        value: "HIGH_TO_LOW_PRICE",
        orderColumns: "price",
        orderDirects: "DESC",
    },
    {
        value: "LOW_TO_HIGH_PRICE",
        orderColumns: "price",
        orderDirects: "ASC",
    },
    {
        value: "MOST_VIEWED",
        orderColumns: "view",
        orderDirects: "DESC",
    },
    {
        value: "FAVORITES",
        orderColumns: "follow",
        orderDirects: "DESC",
    },
];

export enum HISTORY_TYPE {
    SEARCH = "SEARCH",
    DETAILS = "DETAILS",
    COMPARE = "COMPARE",
    DRAW = "DRAW",
    FAVORITE = "FAVORITE",
}

export const MENU_USER_ITEMS = [
    {
        routerLink: `/${APP_PAGES.HISTORY}`,
        icon: "M779.38-153.85 528.92-404.31q-30 25.54-69 39.54t-78.38 14q-95.92 0-162.58-66.65-66.65-66.66-66.65-162.58 0-95.92 66.65-162.58 66.66-66.65 162.58-66.65 95.92 0 162.58 66.65 66.65 66.66 66.65 162.58 0 41.69-14.77 80.69t-38.77 66.69l250.46 250.47-28.31 28.3ZM381.54-390.77q79.61 0 134.42-54.81 54.81-54.8 54.81-134.42 0-79.62-54.81-134.42-54.81-54.81-134.42-54.81-79.62 0-134.42 54.81-54.81 54.8-54.81 134.42 0 79.62 54.81 134.42 54.8 54.81 134.42 54.81Zm-56.92-103.08 20.76-68.77-58.46-47.07h71.85L381.54-680l22.77 70.31h71.84l-58.46 47.07 20.77 68.77-56.92-43.53-56.92 43.53Z",
        text: "Tìm kiếm đã lưu",
        isFullWidth: true,
        isShow: true,
        isShowBadge: true,
        badgeBackgroundColor: "green",
        badgeText: "Mới",
        fragment: "saved",
    },
    {
        routerLink: `/${APP_PAGES.FAVORITE_REAL_ESTATE}`,
        icon: "m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z",
        text: "BĐS yêu thích",
        isFullWidth: true,
        isShow: true,
        isShowBadge: true,
        badgeBackgroundColor: "red",
        badgeText: "Sắp ra mắt",
    },
    {
        icon: "M224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h398.46L800-623.08v398.46q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Zm0-40h510.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7V-600H600v-160H224.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v510.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92ZM300-320h360v-40H300v40Zm0-280h180v-40H300v40Zm0 140h360v-40H300v40ZM200-760v160-160 560-560Z",
        text: "BĐS đã xem gần đây",
        isFullWidth: true,
        isShow: true,
        isShowBadge: true,
        badgeBackgroundColor: "red",
        badgeText: "Sắp ra mắt",
    },
    {
        routerLink: `/${APP_PAGES.PROFILE}`,
        icon: "M247.85-260.62q51-36.69 108.23-58.03Q413.31-340 480-340t123.92 21.35q57.23 21.34 108.23 58.03 39.62-41 63.73-96.84Q800-413.31 800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 66.69 24.12 122.54 24.11 55.84 63.73 96.84ZM480.02-460q-50.56 0-85.29-34.71Q360-529.42 360-579.98q0-50.56 34.71-85.29Q429.42-700 479.98-700q50.56 0 85.29 34.71Q600-630.58 600-580.02q0 50.56-34.71 85.29Q530.58-460 480.02-460ZM480-120q-75.31 0-141-28.04t-114.31-76.65Q176.08-273.31 148.04-339 120-404.69 120-480t28.04-141q28.04-65.69 76.65-114.31 48.62-48.61 114.31-76.65Q404.69-840 480-840t141 28.04q65.69 28.04 114.31 76.65 48.61 48.62 76.65 114.31Q840-555.31 840-480t-28.04 141q-28.04 65.69-76.65 114.31-48.62 48.61-114.31 76.65Q555.31-120 480-120Zm0-40q55.31 0 108.85-19.35 53.53-19.34 92.53-52.96-39-31.31-90.23-49.5Q539.92-300 480-300q-59.92 0-111.54 17.81-51.61 17.81-89.84 49.88 39 33.62 92.53 52.96Q424.69-160 480-160Zm0-340q33.69 0 56.85-23.15Q560-546.31 560-580t-23.15-56.85Q513.69-660 480-660t-56.85 23.15Q400-613.69 400-580t23.15 56.85Q446.31-500 480-500Zm0-80Zm0 350Z",
        text: "Hồ sơ",
        isFullWidth: false,
        isShow: true,
        isShowBadge: false,
    },
    {
        routerLink: `/${APP_PAGES.CHANGE_PASSWORD}`,
        icon: "M100-220v-40h760v40H100Zm21.38-238.92-30.46-17.7 38.62-67.69H52.31v-35.38h77.23l-38.62-65.69 30.46-17.7 38.62 65.7 38.62-65.7 30.46 17.7-38.62 65.69h77.23v35.38h-77.23l38.62 67.69-30.46 17.7-38.62-67.7-38.62 67.7Zm320 0-30.46-17.7 38.62-67.69h-77.23v-35.38h77.23l-38.62-65.69 30.46-17.7 38.62 65.7 38.62-65.7 30.46 17.7-38.62 65.69h77.23v35.38h-77.23l38.62 67.69-30.46 17.7-38.62-67.7-38.62 67.7Zm320 0-30.46-17.7 38.62-67.69h-77.23v-35.38h77.23l-38.62-65.69 30.46-17.7 38.62 65.7 38.62-65.7 30.46 17.7-38.62 65.69h77.23v35.38h-77.23l38.62 67.69-30.46 17.7-38.62-67.7-38.62 67.7Z",
        text: "Đổi mật khẩu",
        isFullWidth: false,
        isShow: true,
        isShowBadge: false,
    },
    {
        routerLink: `/${APP_PAGES.FAVORITE_REAL_ESTATE}`,
        icon: "m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z",
        text: "Yêu thích",
        isFullWidth: false,
        isShow: false,
        isShowBadge: false,
    },
];

export const SLIDE_SHOW = [
    "assets/images/homePage/slide-7.jpg",
    // "assets/images/homePage/slide-3.jpg",
    // "assets/images/homePage/slide-4.jpg",
    // "assets/images/homePage/slide-5.jpg",
    // "assets/images/homePage/slide-6.jpg",
    // "assets/images/homePage/slide-7.jpg",
    // "assets/images/homePage/slide-8.jpg",
    // "assets/images/homePage/slide-9.jpg",
];
