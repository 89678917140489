import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";
import { CategoryService } from "@services/category/category.service";
import { BehaviorSubject, combineLatest } from "rxjs";

@Component({
    selector: "app-item-detail-overview",
    templateUrl: "./item-detail-overview.component.html",
})
export class ItemDetailOverviewComponent implements OnChanges {
    @Input() realEstate: RealEstate;
    public realEstate$: BehaviorSubject<RealEstate>;

    constructor(private categoryService: CategoryService) {
        this.realEstate$ = new BehaviorSubject<RealEstate>(null);
    }

    ngOnChanges({ realEstate }: SimpleChanges): void {
        const { currentValue } = realEstate;
        if (currentValue) {
            combineLatest([
                this.categoryService.getAllType(),
                this.categoryService.getAllDirection(),
            ]).subscribe((res) => {
                const types = res[0];
                const directions = res[1];

                const type =
                    types.find((d) => d.id === currentValue.type)?.title ||
                    currentValue.type;
                const direction =
                    directions.find((d) => d.cKey === currentValue.direction)
                        ?.cValue || "__";

                this.realEstate$.next({ ...currentValue, type, direction });
            });
        }
    }
}
