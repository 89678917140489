import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "transformNumberDecimal",
})
export class TransformNumberDecimalPipe implements PipeTransform {
  transform(value: number): string | number {
    if (value !== null && value) {
      return value.toLocaleString("vi-VN");
    }
    return "--";
  }
}
