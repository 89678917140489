import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-social-icons",
  templateUrl: "./social-icons.component.html"
})
export class SocialIconsComponent implements OnInit {
  @Input() iconSize: string = "";
  @Input() iconColor: string = "";
  constructor() {}

  ngOnInit() {}
}
