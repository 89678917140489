import { Component } from "@angular/core";
import { CLIENTS_IMG } from "@constants/common";
import "swiper/scss";
import "swiper/scss/autoplay";

@Component({
    selector: "app-clients",
    templateUrl: "./clients.component.html",
    styleUrls: ["./clients.component.scss"],
})
export class ClientsComponent {
    public CLIENTS_IMG = CLIENTS_IMG;
    public autoPlayConfig = {
        enabled: true,
        delay: 0,
        disableOnInteraction: true,
    };
}
