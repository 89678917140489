import { Injectable } from '@angular/core';
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import { ContactModel } from "@models/contact/contact.model";
import { Observable } from "rxjs";
import { API_URL } from "@constants/backend-url.constants";

@Injectable({
    providedIn: 'root'
})
export class ContactService extends BaseHttpRequest {
    addContact(payload: ContactModel): Observable<any> {
        return this.post(`${API_URL.SUBMIT_CONTACT}`, payload);
    }

}
