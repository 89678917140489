import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { DirectivesModule } from "@directives/directives.module";
import { SelectRangeModule } from "app/public/modules/input-select-range/select-range.module";
import { RealEstateInfoModule } from "app/public/modules/real-estate-info/real-estate-info.module";
import { PipesModule } from "@pipes/pipes.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRippleModule } from "@angular/material/core";
import { RealEstateSuggestionComponent } from './real-estate-suggestion/real-estate-suggestion.component';
import { RegionsSuggestionComponent } from './regions-suggestion/regions-suggestion.component';
import {
    InputSearchSuggestionComponent
} from "app/public/modules/search-suggestion/input-search/input-search-suggestion.component";
import { RealEstateSuggestionNearLocationComponent } from './real-estate-suggestion-near-location/real-estate-suggestion-near-location.component';
import { RecentResearchComponent } from './recent-research/recent-research.component';

const COMPONENTS = [
    InputSearchSuggestionComponent,
    RealEstateSuggestionComponent,
    RegionsSuggestionComponent,
]


@NgModule({
    declarations: [...COMPONENTS, RealEstateSuggestionNearLocationComponent, RecentResearchComponent],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        FlexModule,
        MatButtonModule,
        ExtendedModule,
        DirectivesModule,
        SelectRangeModule,
        RealEstateInfoModule,
        PipesModule,
        MatTooltipModule,
        MatRippleModule
    ]
})
export class InputSearchSuggestModule {
}
