import { Component, OnInit } from "@angular/core";
import { SLIDE_SHOW } from "@constants/common";

@Component({
    selector: "app-home-layout-container",
    templateUrl: "./home-layout-container.component.html",
})
export class HomeLayoutContainerComponent implements OnInit {
    readonly SLIDE_SHOW = SLIDE_SHOW;

    ngOnInit() {
    }
}
