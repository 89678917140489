import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  templateUrl: "./notify-for-agent.component.html",
  styleUrls: ["./notify-for-agent.component.scss"],
})
export class NotifyForAgentModalComponent {
  constructor(
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public closeModal(): void {
    this.dialog.closeAll();
  }
}
