import { Component, Input } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";

@Component({
  selector: "app-item-detail-exterior",
  templateUrl: "./item-detail-exterior.component.html",
})
export class ItemDetailExteriorComponent {
  @Input() realEstate: RealEstate;

  constructor() {}
}
