import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupContactWithUsComponent } from "app/public/modules/contact/group-contact-with-us/group-contact-with-us.component";

const COMPONENTS = [
    GroupContactWithUsComponent
]

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule
    ]
})
export class ContactModule {
}
