import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getMediumImage",
})
export class GetMediumImagePipe implements PipeTransform {
  public SMALL_MEDIUM = "700x480";

  transform(url: string): string {
    if (url) {
      return url.replace("0x0", this.SMALL_MEDIUM);
    }
    return url;
  }
}
