import { Component, HostListener, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SearchRealEstateParams } from "@models/land/real-estate.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-header-image-v2",
    templateUrl: "./header-image-v2.component.html",
    styleUrls: ["./header-image-v2.component.scss"],
})
export class HeaderImageV2Component {
    @Input("backgroundImage") backgroundImage: string[];

    public isTablet: boolean =
        this.mediaMatcher.matchMedia("(max-width: 980px)").matches;
    public isFixed: boolean;
    public searchForm: FormGroup = this.fb.group(
        new SearchRealEstateParams({}),
    );

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private mediaMatcher: MediaMatcher,
    ) {
    }

    public updateParam(params: any): void {
        // @TODO : Push
        debugger
        this.searchForm.patchValue({
            page: 0,
        });
        this.router.navigate([APP_PAGES.SEARCH], {
            relativeTo: this.activeRoute,
            queryParams: { address: params?.description },
        }).then();
    }

    @HostListener("document: scroll") onWindowScroll() {
        const scrollTopPosition = window.scrollY;

        scrollTopPosition > 100
            ? (this.isFixed = true)
            : (this.isFixed = false);
    }
}
