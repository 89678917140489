import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanDeactivate,
    CanLoad,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { KEY } from "@constants/backend-url.constants";
import { AuthModalComponent } from "@pages/auth/auth-modal/auth-modal.component";
import { AuthService } from "@services/auth/auth.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Observable } from "rxjs";
import { APP_PAGES } from "@constants/app-url.constants";

@Injectable({
    providedIn: "root",
})
export class AuthGuard
    implements CanActivate, CanLoad, CanDeactivate<any>, CanActivateChild
{
    constructor(
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private storageService: TokenStorageService
    ) {}

    // Check can active route
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const isLogin = this.authService.isLogin();

        if (isLogin && Object.keys(isLogin).length) {
            return true;
        } else {
            this.router.navigate([APP_PAGES.DEFAULT]).then();
            this.dialog.open(AuthModalComponent, {
                panelClass: "modal-panel",
            });

            this.storageService.set(KEY.REDIRECT_URL, state.url);

            return false;
        }
    }

    // Check children can active
    canActivateChild():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return true;
    }

    // Check can deactive
    canDeactivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return true;
    }

    // Check can load module lazyloading.
    canLoad():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.authService.isLogin();
    }
}
