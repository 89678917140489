import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-share-social",
    templateUrl: "./share-social.component.html",
    styleUrls: ["./share-social.component.scss"],
})
export class ShareSocialComponent {
    public currentUrl: string = window.location.href;
    public tooltipMsg = "Copy link";

    constructor(
        public dialogRef: MatDialogRef<ShareSocialComponent>,
    ) {
    }

    close() {
        this.dialogRef.close();
    }

    onClickCopy() {
        this.tooltipMsg = `COPIED: ${this.currentUrl}`;
        navigator.clipboard.writeText(this.currentUrl).then();
    }
}
