import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Optional,
    Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { KEY } from "@constants/backend-url.constants";
import { HistorySearchingOfCustomerService } from "@services/customer/history-searching-of-customer.service";
import { NotifyService } from "@services/common/notify.service";
import {
    CreateHistorySearchingUserModel,
    UpdateHistorySearchingUserModel,
} from "@models/user/history-searching-user.model";
import { catchError, throwError } from "rxjs";

@Component({
    selector: "app-edit-saved-search-modal",
    templateUrl: "./edit-saved-search-modal.component.html",
    styleUrls: ["./edit-saved-search-modal.component.scss"],
})
export class EditSavedSearchModalComponent implements OnInit {
    @Output() listChanged = new EventEmitter();
    public modalHeading: string = "Sửa tìm kiếm đã lưu";
    public savedSearchForm: FormGroup;
    public userId: number;
    public addressSearch: string;

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private storageService: TokenStorageService,
        private historySearchingService: HistorySearchingOfCustomerService,
        private notifyService: NotifyService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.getUserId();
        this.patchValueToForm();
    }

    get controls() {
        return this.savedSearchForm.controls;
    }

    public onUpdateHistorySearch() {
        const payload: UpdateHistorySearchingUserModel = {
            key: this.data?.searchHistory.key,
            hash: this.data?.searchHistory.hash,
            name: this.savedSearchForm.getRawValue().name,
            subscribe: this.savedSearchForm.getRawValue().subscribe,
        };

        if (this.data?.searchHistory && this.savedSearchForm.valid) {
            this.historySearchingService
                .updateSearchingRealEstate(payload, this.userId)
                .pipe(
                    catchError((error) => {
                        this.notifyService.waring(
                            "Sửa lịch sử tìm kiếm thất bại!"
                        );
                        return throwError(() => error);
                    })
                )
                .subscribe((_) => {
                    this.listChanged.emit();
                    this.dialog.closeAll();
                    this.notifyService.success(
                        "Sửa lịch sử tìm kiếm thành công!"
                    );
                });
        }
    }

    public onCreateHistorySearch(): boolean {
        if (!this.data?.searchHistory && this.savedSearchForm.valid) {
            const payload = this.getPayload();
            this.historySearchingService
                .saveSearchingRealEstate(payload, this.userId)
                .pipe(
                    catchError((error) => {
                        this.notifyService.waring(
                            "Lưu lịch sử tìm kiếm thất bại!"
                        );
                        return throwError(() => error);
                    })
                )
                .subscribe((_) => {
                    this.dialog.closeAll();
                    this.notifyService.success(
                        "Lưu lịch sử tìm kiếm thành công!"
                    );
                });
            return true;
        }
        return false;
    }

    public onCloseModal() {
        this.dialog.closeAll();
    }

    private getUserId() {
        this.userId = this.storageService.get(KEY.USER_LOGIN)?.id;
    }

    private initForm(): void {
        this.savedSearchForm = this.fb.group({
            name: [this.addressSearch, Validators.required],
            subscribe: true,
        });
    }

    private patchValueToForm(): void {
        if (this.data?.searchHistory) {
            this.controls["name"].setValue(this.data.searchHistory.name);
            this.controls["subscribe"].setValue(
                this.data.searchHistory.subscribe
            );
        }
    }

    private getPayload() {
        try {
            const historySearching = this.storageService.get(
                KEY.SEARCH_HISTORY
            );

            const { name, subscribe } = this.savedSearchForm.getRawValue();
            const payload: CreateHistorySearchingUserModel = {
                ...historySearching,
                name,
                subscribe,
            };

            return payload;
        } catch (e) {
            return null;
        }
    }
}
