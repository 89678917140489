import { Component } from "@angular/core";
import { APP_PAGES } from "@constants/app-url.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { LAND_PURPOSE } from "@constants/common";

@Component({
    selector: "app-introduction",
    templateUrl: "./introduction.component.html",
    styleUrls: ["./introduction.component.scss"],
})
export class IntroductionComponent {
    public INTRODUCTION_DETAIL = [
        {
            heading: "Các bất động sản mở bán",
            description:
                "Bạn có thể tìm thấy căn nhà mơ ước hay cơ hội đầu tư hấp dẫn thông qua lượng tin lớn, uy tín về các loại hình BĐS",
            imageUrl: "/assets/images/homePage/buy-a-home.webp",
            purpose: LAND_PURPOSE.FOR_SELL,
        },
        {
            heading: "Mặt bằng cho thuê",
            description:
                "Cập nhật thường xuyên và đầy đủ về các loại hình BĐS cho thuê giúp bạn nhanh chóng tìm được BĐS ưng ý",
            imageUrl: "/assets/images/homePage/sell-a-home.webp",
            purpose: LAND_PURPOSE.FOR_RENT,
        },
        {
            heading: "Wiki BĐS",
            description:
                "Chúng tôi cung cấp kiến thức, kinh nghiệm và mọi thông tin cần thiết để người tìm nhà tìm thấy căn nhà mơ ước",
            imageUrl: "/assets/images/homePage/rent-a-home.webp",
            purpose: "",
        },
    ];

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    public onRedirect(purpose: string) {
        if (purpose) {
            this.router.navigate([APP_PAGES.SEARCH], {
                relativeTo: this.route,
                queryParams: { purpose: purpose },
            }).then();
        }
    }
}
