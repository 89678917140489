import { Injectable } from "@angular/core";
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import { API_URL } from "@constants/backend-url.constants";
import {
  CreateHistorySearchingUserModel,
  UpdateHistorySearchingUserModel,
} from "@models/user/history-searching-user.model";
import { Observable } from "rxjs";
import { HttpResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HistorySearchingOfCustomerService {
  constructor(private httpBaseService: BaseHttpRequest) {}

  public getSearchingRealEstateList(userId: number): Observable<any> {
    return this.httpBaseService.get(
      `${API_URL.CRM}/${userId}/${API_URL.HISTORY_SEARCH}`
    ) as Observable<any>;
  }

  public saveSearchingRealEstate(
    payload: CreateHistorySearchingUserModel,
    userId: number
  ) {
    const url = `${API_URL.CRM}/${userId}/${API_URL.HISTORY_SEARCH}`;
    return this.httpBaseService.post(url, payload);
  }

  public deleteSaveSearchingRealEstate(userId: number, hash: string) {
    const url = `${API_URL.CRM}/${userId}/${API_URL.HISTORY_SEARCH}?hash=${hash}`;
    return this.httpBaseService.delete(url);
  }

  public updateSearchingRealEstate(
    payload: UpdateHistorySearchingUserModel,
    userId: number
  ) {
    const url = `${API_URL.CRM}/${userId}/${API_URL.HISTORY_SEARCH}`;
    return this.httpBaseService.patch(url, payload);
  }
}
