import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ContactConsultantV2Component } from "app/public/modules/contact-consultant-v2/contact-consultant-v2.component";

@Component({
    selector: "app-group-contact-with-us",
    templateUrl: "./group-contact-with-us.component.html",
    styleUrls: ["./group-contact-with-us.component.scss"],
})
export class GroupContactWithUsComponent {
    constructor(private matDialog: MatDialog) {
    }

    onClickOpenDialog() {
        this.matDialog.open(ContactConsultantV2Component, {
            panelClass: "modal-panel",
        });
    }
}
