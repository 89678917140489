import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";

import { DirectivesModule } from "@directives/directives.module";
import { PipesModule } from "@pipes/pipes.module";
import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";

import { OverlayModule } from "@angular/cdk/overlay";
import { ContactConsultantComponent } from "app/public/modules/contact-consultant/contact-consultant.component";
import { LandItemComponent } from "app/public/modules/land-item/land-item.component";
import { SelectFilterComponent } from "app/public/modules/select-sort/select-filter.component";
import { NgOtpInputModule } from "ng-otp-input";
import { BookOrderComponent } from "./book-order/book-order.component";
import { PropertiesToolbarComponent } from "./properties-toolbar/properties-toolbar.component";
import { ShareSocialComponent } from "./share-social/share-social.component";

import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { GetMediumImagePipe } from "@pipes/image/get-medium-image.pipe";
import { ImgSizeThumbnailPipe } from "@pipes/image/image-size-thumbnail.pipe";
import {
    AccountWatingApprovedComponent,
} from "app/public/modules/account-wating-approved/account-wating-approved.component";
import { ClientsComponent } from "app/public/modules/clients/clients.component";
import { ContactConsultantV2Component } from "app/public/modules/contact-consultant-v2/contact-consultant-v2.component";
import { LandFavoriteItemComponent } from "app/public/modules/land-favorite-item/land-favorite-item.component";
import { LandItemMobileComponent } from "app/public/modules/land-item-mobile/land-item-mobile.component";
import {
    ModalSimilarRealEstatesComponent,
} from "app/public/modules/modal-similar-real-estates/modal-similar-real-estates.component";
import { InputSearchSuggestModule } from "app/public/modules/search-suggestion/input-search-suggest.module";
import { SocialIconsComponent } from "app/public/modules/social-icons/social-icons.component";
import {
    TooManyRequestsNotificationComponent,
} from "app/public/modules/too-many-requests-notification/too-many-requests-notification.component";
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper";
import { SwiperModule } from "swiper/angular";
import { CompareLandItemComponent } from "./compare-land-item/compare-land-item.component";
import { ConfirmModalComponent } from "./confirm-modal/confirm-modal.component";
import { EditSavedSearchModalComponent } from "./edit-saved-search-modal/edit-saved-search-modal.component";
import { IntroductionComponent } from "./introduction/introduction.component";
import { NotifyForAgentModalComponent } from "./notify-for-agent/notify-for-agent.component";
import { RequestNotificationComponent } from "./request-notification/request-notification.component";
import { SkeletonLoadingComponent } from "./skeleton-loading/skeleton-loading.component";
import { Skeleton2LoadingComponent } from "./skeleton2-loading/skeleton2-loading.component";
import { SwiperProductComponent } from "./swiper-product/swiper-product.component";

SwiperCore.use([Pagination, Navigation, Virtual, Autoplay]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: false,
    suppressScrollX: true,
};

const COMPONENTS = [
    ModalSimilarRealEstatesComponent,
    SocialIconsComponent,
    ContactConsultantV2Component,
    TooManyRequestsNotificationComponent,
    CompareLandItemComponent,
    IntroductionComponent,
    PropertiesToolbarComponent,
    LandItemComponent,
    BookOrderComponent,
    ContactConsultantComponent,
    ShareSocialComponent,
    SwiperProductComponent,
    SelectFilterComponent,
    ClientsComponent,
    LandItemMobileComponent,
    SkeletonLoadingComponent,
    LandFavoriteItemComponent,
    Skeleton2LoadingComponent,
    NotifyForAgentModalComponent,
    AccountWatingApprovedComponent,
    EditSavedSearchModalComponent,
    ConfirmModalComponent,
];
const MODULES = [
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    NgOtpInputModule,
    OverlayModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
];

@NgModule({
    imports: [...MODULES, InputSearchSuggestModule],
    exports: [...MODULES, ...COMPONENTS, RequestNotificationComponent],
    declarations: [...COMPONENTS, RequestNotificationComponent],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        ImgSizeThumbnailPipe,
        GetMediumImagePipe,
        { provide: MAT_DATE_LOCALE, useValue: "vi-VN" },
    ],
})
export class SharedModule {
}
