import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "input[onlyPhoneNumber]",
})
export class OnlyPhoneNumberDirective {
  public MAX_LENGTH = 10;

  constructor() {}

  @HostListener("keypress", ["$event"]) onInputChange(e) {
    const verified = String.fromCharCode(e.which).match(/[^0-9]/g);
    if (verified || e.target.value?.length === this.MAX_LENGTH) {
      e.preventDefault();
      return false;
    }
  }
}
