import { Component, OnDestroy, OnInit } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { ActivatedRoute, Router } from "@angular/router";
import { APP_PAGES } from "@constants/app-url.constants";
import { KEY } from "@constants/backend-url.constants";
import { RealEstate } from "@models/land/real-estate.model";
import { GetShortAddressPipe } from "@pipes/get-short-address.pipe";
import { MetaTagService } from "@services/common/meta-tag.service";
import { PolygonAreaService } from "@services/google-maps/polygon-area.service";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";
import { MapRealEstateState } from "@services/lands/map-real-estate.state";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";
import Swiper from "swiper";

@Component({
    selector: "app-real-estate-for-customer",
    templateUrl: "./real-estate-for-customer.component.html",
    styleUrls: ["./real-estate-for-customer.component.scss"],
})
export class RealEstateForCustomerComponent implements OnInit, OnDestroy {
    public watcher: Subscription;
    public viewCol: number = 25;
    public viewType: string = "grid";
    public limitItemLand = 8;
    public count: number = 8;
    public isVisible: boolean = true;
    public lands: RealEstate[];
    readonly APP_PAGES = APP_PAGES;
    public swiperEnd: boolean;
    public swiperBegin: boolean;

    constructor(
        public landService: HttpRealEstateService,
        public router: Router,
        private activeRoute: ActivatedRoute,
        private shortAddressPipe: GetShortAddressPipe,
        public mediaObserver: MediaObserver,
        public metaTag: MetaTagService,
        private storageService: TokenStorageService,
        private polygonService: PolygonAreaService,
        private mapState: MapRealEstateState,
    ) {
        this.observeResponsive();
    }

    ngOnInit() {
        this.getProducts();
        this.metaTag.setMetaTag({});
        this.metaTag.setTitle("Trang chủ");
        this.storageService.delete(KEY.REDIRECT_URL);
    }

    private observeResponsive() {
        this.watcher = this.mediaObserver
            .asObservable()
            .pipe(
                filter((changes: MediaChange[]) => changes.length > 0),
                map((changes: MediaChange[]) => changes[0]),
            )
            .subscribe((change: MediaChange) => {
                if (change.mqAlias == "xs") {
                    this.viewCol = 100;
                } else if (change.mqAlias == "sm") {
                    this.viewCol = 50;
                } else if (change.mqAlias == "md") {
                    this.viewCol = 33.3;
                } else {
                    this.viewCol = 25;
                }
            });
    }

    public getProducts(params?: any) {
        const body = !params
            ? {
                orderColumns: "updated",
                orderDirects: "DESC",
            }
            : params;
        this.landService
            .getProducts({ ...body, page: 0, limit: this.count })
            .then(({ data }) => {
                this.lands = [...data];
                this.isVisible = false;
            });
    }

    public handleEventSelectedLand(realEstate: RealEstate): void {
        this.shortAddressPipe
            .transform(realEstate?.address)
            .subscribe((address) => {
                this.router
                    .navigate([APP_PAGES.SEARCH], {
                        relativeTo: this.activeRoute,
                        queryParams: {
                            itemId: realEstate.id,
                            purpose: realEstate.purpose,
                            address,
                        },
                    })
                    .then();
                this.mapState.houseMarker = realEstate;
                this.setPointForDetail(realEstate);
            });
    }

    private setPointForDetail(realEstate: RealEstate) {
        const data = {
            lat: Number(realEstate.address?.latitude),
            lng: Number(realEstate.address?.longitude),
            type: ["street_address"],
        };

        this.polygonService.setLocation = data;
        this.polygonService.setZoom = data;
    }

    ngOnDestroy() {
        this.watcher.unsubscribe();
    }
}
