import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "app/public/modules/shared.module";
import { AuthGuard } from "@guard/auth.guard";
import { SavedSearchesComponent } from "./saved-searches/saved-searches.component";
import { NgxPaginationModule } from "ngx-pagination";
import { UserMenuComponent } from "app/public/modules/user-menu/user-menu.component";
import { APP_PAGES } from "@constants/app-url.constants";
import { PreventUserUnactiveGuard } from "@guard/prevent-user-unactive.guard";

export const routes = [
    {
        path: APP_PAGES.HISTORY,
        component: SavedSearchesComponent,
        canActivate: [AuthGuard, PreventUserUnactiveGuard],
    },
    {
        path: APP_PAGES.PROFILE,
        component: UserProfileComponent,
        canActivate: [AuthGuard, PreventUserUnactiveGuard],
    },
    {
        path: APP_PAGES.CHANGE_PASSWORD,
        component: ChangePasswordComponent,
        canActivate: [AuthGuard, PreventUserUnactiveGuard],
    },
];

const COMPONENTS = [
    ChangePasswordComponent,
    UserProfileComponent,
    UserMenuComponent,
    SavedSearchesComponent,
];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        SharedModule,
        CommonModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        RouterModule.forChild(routes),
    ],
})
export class UserMenuModule {
}
