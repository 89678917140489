import { Pipe, PipeTransform } from "@angular/core";
import { combineLatest, mergeMap, Observable, of } from "rxjs";
import { CategoryService } from "@services/category/category.service";
import { AddressModel } from "@models/land/address.model";

@Pipe({
  name: "getShortAddress",
})
export class GetShortAddressPipe implements PipeTransform {
  public cities: any[];
  public towns: any[];
  public districts: any[];

  constructor(private categoryService: CategoryService) {}

  transform(address: AddressModel): Observable<string> {
    if (address) {
      return combineLatest([
        this.categoryService.getAllCity(),
        this.categoryService.getAllTown(),
        this.categoryService.getAllDistrict(),
      ]).pipe(
        mergeMap((data) => {
          const _cities = data[0].find((t) => t.cKey === address.city);
          const _town = data[1].find((t) => t.cKey === address.town);
          const _districts = data[2].find((t) => t.cKey === address.district);

          const fullDistrict = [_town?.cValue, _districts?.cValue]
            .filter(Boolean)
            .join(", ");
          return of([fullDistrict, _cities?.cValue].filter(Boolean).join(", "));
        })
      );
    } else {
      return of("");
    }
  }
}
