import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpStatusCode,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import {
    TooManyRequestsNotificationComponent
} from "app/public/modules/too-many-requests-notification/too-many-requests-notification.component";

@Injectable()
export class Http429Interceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.TooManyRequests) {
          this.onHandleError429();
        }
        return throwError(error);
      })
    );
  }

  public onHandleError429(data?: any) {
    this.dialog?.open(TooManyRequestsNotificationComponent, {
      maxWidth: "650px",
      data,
      panelClass: "notification-all",
    });
  }
}
