import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {

    constructor() {
    }
    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    get(key: string) {
        try {
            return JSON.parse(localStorage.getItem(key)) || null;
        } catch (error) {
            return null;
        }
    }
    delete(key) {
        localStorage.removeItem(key);
    }

}
