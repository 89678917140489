export const PRICE_FOR_RENT = [
  {
    key: "Tất cả mức giá thuê",
    start: 0,
    end: 100_000_000_000,
  },
  {
    key: "Dưới 30",
    start: 0,
    end: 30_000_000,
  },
  {
    key: "30 - 50",
    start: 30_000_000,
    end: 50_000_000,
  },
  {
    key: "50 - 100",
    start: 50_000_000,
    end: 100_000_000,
  },
  {
    key: "100 - 200",
    start: 100_000_000,
    end: 200_000_000,
  },
  {
    key: "Trên 200",
    start: 200_000_000,
    end: 100_000_000_000,
  },
];

export const PRICE_FOR_SELL = [
  {
    key: "Tất cả mức giá bán",
    start: 0,
    end: 100_000_000_000,
  },
  {
    key: "Dưới 2",
    start: 0,
    end: 2_000_000_000,
  },
  {
    key: "2 - 5",
    start: 2_000_000_000,
    end: 5_000_000_000,
  },
  {
    key: "5 - 10",
    start: 5_000_000_000,
    end: 10_000_000_000,
  },
  {
    key: "10 - 20",
    start: 10_000_000_000,
    end: 20_000_000_000,
  },
  {
    key: "Trên 20",
    start: 20_000_000_000,
    end: 10000_000_000_000,
  },
];

export const AREA_FRONT = [
  {
    key: "Tất cả mặt tiền",
    start: 0,
    end: 1000000,
  },
  {
    key: "Dưới 5",
    start: 0,
    end: 5,
  },
  {
    key: "5 - 10",
    start: 5,
    end: 10,
  },
  {
    key: "10 - 20",
    start: 10,
    end: 20,
  },
  {
    key: "20 - 50",
    start: 20,
    end: 50,
  },
  {
    key: "Trên 50",
    start: 50,
    end: 100000,
  },
];

export const SQUARE = [
  {
    key: "Tất cả diện tích",
    start: 0,
    end: 10000,
  },
  {
    key: "Dưới 50",
    start: 0,
    end: 50,
  },
  {
    key: "50 - 100",
    start: 50,
    end: 100,
  },
  {
    key: "100 - 150",
    start: 100,
    end: 150,
  },
  {
    key: "150 - 200",
    start: 150,
    end: 200,
  },
  {
    key: "Trên 200",
    start: 200,
    end: 10000,
  },
];
