export const environment = {
    production: false,
    url: "https://dev-api.hoaland.com/api/gateway/v1/",
    apiUrl: "https://dev-api.hoaland.com/api/gateway/v1",
    googleApiKey: "AIzaSyAooXtTDymTYgxCaO68SpxWTlf6S2YnfeY",
    appVersion: "1.0.3",
    recaptcha: "6LdGosoiAAAAACt6ftxMkajPUD4xitEk40aVhUKW",

    firebaseConfig: {
        apiKey: "AIzaSyDMvXfU5VraSKYyr1oNd_t1MX7fAo1Dxvg",
        authDomain: "hoaland-notification-dev.firebaseapp.com",
        databaseURL: "https://hoaland-notification-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "hoaland-notification-dev",
        storageBucket: "hoaland-notification-dev.appspot.com",
        messagingSenderId: "966752575108",
        appId: "1:966752575108:web:6a2eb45279958686772cd1",
        measurementId: "G-K10PL9XCH2",

        vapidKey: "BBvv66uAsLJn8G5huDT6Er9MRRX7kWiPkYufBEbioOu-hZCojxC_jNROovcCf6KZGCGOVYfnnNnKyp5i0Ub_jw0"

    }
};
