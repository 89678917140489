import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppHeaderComponent } from "app/public/modules/app-header/header/app-header.component";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { FavoriteRealEstateModule } from "app/public/modules/favorite-real-estate/favorite-real-estate.module";
import { InputSearchSuggestModule } from "app/public/modules/search-suggestion/input-search-suggest.module";
import { LogoModule } from "app/public/modules/logo/logo.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { UserMenuModule } from "app/public/modules/user-menu/user-menu.module";
import { SidenavMobileComponent } from "app/public/modules/app-header/sidenav-mobile/sidenav-mobile.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { VerticalMenuComponent } from "app/public/modules/app-header/vertical-menu/vertical-menu.component";
import { HorizontalMenuComponent } from "app/public/modules/app-header/horizontal-menu/horizontal-menu.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { Toolbar1Component } from "app/public/modules/app-header/tool-bar/toolbar1.component";
import { DirectivesModule } from "@directives/directives.module";

const COMPONENTS = [
    SidenavMobileComponent,
    AppHeaderComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    Toolbar1Component

];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        ExtendedModule,
        UserMenuModule,
        FavoriteRealEstateModule,
        FlexModule,
        InputSearchSuggestModule,
        LogoModule,
        MatButtonModule,
        MatDividerModule,
        MatToolbarModule,
        RouterModule,
        MatSidenavModule,
        PerfectScrollbarModule,
        DirectivesModule
    ],
})
export class HeaderLayoutModule {
}
