import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[appAutoFocus]",
})
export class AutoFocusDirective implements AfterViewInit {
  public focusTag = ["input", "select", "textarea"];

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    const localName = this.element.nativeElement?.localName;
    if (this.focusTag.includes(localName)) this.element.nativeElement.focus();
  }
}
