import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";

@Component({
  selector: "select-room-group",
  templateUrl: "./select-room-group.component.html",
  styleUrls: ["./select-room-group.component.scss"],
})
export class SelectRoomGroupComponent implements OnInit {
  @Input() searchForm: FormGroup;
  @Output() selectedEmitted: EventEmitter<any> = new EventEmitter();
  
  public isOpen = false;
  public isCheck = new FormControl();
  public bedroom: FormControl;
  public toilet: FormControl;
  public isMobileScreen: boolean;

  constructor(public mediaMatcher: MediaMatcher) {
    this.isMobileScreen = mediaMatcher.matchMedia("(max-width: 599px)").matches;
  }

  handleApply(): void {
    this.isOpen = !this.isOpen;
    this.selectedEmitted.emit();
  }

  ngOnInit(): void {
    this.searchForm.patchValue({
      bedroom: this.searchForm.get("bedroom").value.toString(),
    });
    this.searchForm.patchValue({
      toilet: this.searchForm.get("toilet").value.toString(),
    });

    this.bedroom = this.searchForm.get("bedroom") as FormControl;
    this.toilet = this.searchForm.get("toilet") as FormControl;
  }

  onChangeField({ value }, key: string) {
    this.searchForm.controls[key].setValue(value.toString());
  }
}
