import { Component } from "@angular/core";
import { filter, map } from "rxjs/operators";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-skeleton2-loading",
  templateUrl: "./skeleton2-loading.component.html",
  styleUrls: ["./skeleton2-loading.component.scss"],
})
export class Skeleton2LoadingComponent {
  public viewType = "grid";
  public viewCol = 33.3;
  public subscription = new Subject();

  constructor(public mediaObserver: MediaObserver) {
    this.subscriberMediaChange();
  }

  subscriberMediaChange(): void {
    this.mediaObserver
      .asObservable()
      .pipe(
        filter((changes: MediaChange[]) => changes.length > 0),
        map((changes: MediaChange[]) => changes[0]),
        takeUntil(this.subscription)
      )
      .subscribe((change: MediaChange) => {
        if (change.mqAlias == "xs") {
          this.viewCol = 100;
        } else if (change.mqAlias == "sm") {
          this.viewCol = 50;
        } else if (change.mqAlias == "lg") {
          this.viewCol = 33.3;
        } else {
          this.viewCol = 33.3;
        }
      });
  }
}
