import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { MenuService } from "app/public/modules/app-header/configs/menu.service";
import { Menu } from "app/public/modules/app-header/configs/menu.model";
import { ActivatedRoute } from "@angular/router";
import { MediaMatcher } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { UserModel } from "@models/user/user.model";
import { AuthService } from "@services/auth/auth.service";

@Component({
    selector: "app-horizontal-menu",
    templateUrl: "./horizontal-menu.component.html",
    styleUrls: ["./horizontal-menu.component.scss"],
    providers: [MenuService],
})
export class HorizontalMenuComponent implements OnInit, OnChanges {
    @Input() menuParentId: string | number;

    public userInfo: Observable<UserModel> = this.authService.getUserInfo();
    public menuItems: Array<Menu> = this.menuService.getMenuItems();
    public menuSelected: number | string;
    public menuIcon: Array<string>;

    constructor(
        public mediaMatcher: MediaMatcher,
        public menuService: MenuService,
        public activeRouted: ActivatedRoute,
        public authService: AuthService,
    ) {
        this.menuIcon = [
            "M240-200h147.69v-235.38h184.62V-200H720v-360L480-741.54 240-560v360Zm-40 40v-420l280-211.54L760-580v420H532.31v-235.38H427.69V-160H200Zm280-310.77Z",
            "M760-369.23v-280L540-803.08 320-649.23v107.69h-40v-126.92l260-185.39 260 185.39v299.23h-40ZM540-803.08Zm23.85 180.77h32.3v-32.31h-32.3v32.31Zm-80 0h32.3v-32.31h-32.3v32.31Zm80 80h32.3v-32.31h-32.3v32.31Zm-80 0h32.3v-32.31h-32.3v32.31Zm-227.7 328.46 302.62 88.31 239.54-74q-1.16-18.23-10.89-26.27-9.74-8.04-22.04-8.04H575.6q-25.37 0-45.98-2-20.62-2-42.24-9.53l-87.61-28.7 13.54-39.54 81 29.31q19.31 7.31 43.84 8.77 24.54 1.46 67.23 1.69 0-18.69-6.88-30.23t-19.73-16.07l-230.15-84.47q-2.31-.76-4.24-1.15-1.92-.38-4.23-.38h-84v192.3ZM96.15-120v-326.15h243.1q5.6 0 11.52 1.26 5.92 1.27 11 2.97l231.15 84.69q21.46 8.15 36.96 29.31 15.5 21.15 15.5 54.07h120q36.16 0 55.39 22.62Q840-228.62 840-193.85v24.62L560.77-83.08l-304.62-88.77V-120h-160Zm40-40h80v-246.15h-80V-160Z",
            "M200-160v-365.77l-77.38 59.62-24.16-31.7L480-790l381.54 292.15-24.16 31.47L760-525.77V-160H200Zm40-40h480v-356L480-739 240-556v356Zm0 0h480-480Zm80-169.23q-12.38 0-21.58-9.19-9.19-9.2-9.19-21.58 0-12.38 9.19-21.58 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.2 9.19 21.58 0 12.38-9.19 21.58-9.2 9.19-21.58 9.19Zm160 0q-12.38 0-21.58-9.19-9.19-9.2-9.19-21.58 0-12.38 9.19-21.58 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.2 9.19 21.58 0 12.38-9.19 21.58-9.2 9.19-21.58 9.19Zm160 0q-12.38 0-21.58-9.19-9.19-9.2-9.19-21.58 0-12.38 9.19-21.58 9.2-9.19 21.58-9.19 12.38 0 21.58 9.19 9.19 9.2 9.19 21.58 0 12.38-9.19 21.58-9.2 9.19-21.58 9.19Z",
            "M460-300h40v-220h-40v220Zm20-276.92q10.46 0 17.54-7.08 7.08-7.08 7.08-17.54 0-10.46-7.08-17.54-7.08-7.07-17.54-7.07-10.46 0-17.54 7.07-7.08 7.08-7.08 17.54 0 10.46 7.08 17.54 7.08 7.08 17.54 7.08Zm.13 456.92q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z",
        ];
    }

    public ngOnInit() {
        this.menuItems.forEach((item, index) => {
            item["classNav"] = this.menuIcon[index % this.menuIcon.length]; //lấy index chia độ dài mảng icon index 0 chia 5 = 0 lấy phần tử 0 tiếp theo....
        });

        const isDesktopScreen =
            this.mediaMatcher.matchMedia("(min-width: 599px)").matches;

        if (isDesktopScreen) {
            if (this.menuItems.length > 4) {
                this.menuItems.splice(3, 1);
            }
        }
        this.getParamUrl();
    }

    public getParamUrl(): void {
        this.activeRouted.queryParams.subscribe(({ purpose }) => {
            if (purpose) {
                this.menuSelected = Number(purpose);
            }
        });
    }

    public ngOnChanges({ menuParentId }: SimpleChanges): void {
        this.menuSelected = menuParentId?.currentValue;
    }
}
