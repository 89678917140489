import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'real-estate-suggestion-near-location',
  templateUrl: './real-estate-suggestion-near-location.component.html',
  styleUrls: ['./real-estate-suggestion-near-location.component.scss']
})
export class RealEstateSuggestionNearLocationComponent {
    @Output() selected = new EventEmitter();
}
