import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable } from "rxjs";
import { HttpRequest } from "@angular/common/http";
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import { UserModel } from "@models/user/user.model";
import {
    AuthResponse,
    LoginModel,
    RegisterModel,
    ResendOTP,
    VerifyOTPModel,
} from "@models/auth/auth.model";
import { API_URL, KEY } from "@constants/backend-url.constants";
import { JwtTokenService } from "@services/jwt-token.service";

@Injectable({
    providedIn: "root",
})
export class AuthService extends BaseHttpRequest {
    jwtService: JwtTokenService;
    private userLogin$ = new BehaviorSubject<UserModel>(null);

    get userType(): string {
        this.jwtService = this.injector.get(JwtTokenService);
        const { USER_TYPE } = this.jwtService.encodeJwt() || ({} as UserModel);
        return USER_TYPE;
    }

    havePermission(allowedPermissions: string[]): boolean {
        const permissions = this.storageService.get(KEY.USER_PERMISSIONS) || [];
        const hasPermission = (currentValue) =>
            permissions.includes(currentValue);

        return allowedPermissions.every(hasPermission);
    }

    /**
     * Request forgot password OTP
     * @param payload
     */
    forgotPwd(payload: any): Observable<AuthResponse> {
        return this.post<AuthResponse>(API_URL.forgotPwd, payload);
    }

    /**
     * Verify FPW OTP
     * @param payload
     */
    verifyOTPForgotPwd(payload: VerifyOTPModel): Observable<AuthResponse> {
        return this.post<AuthResponse>(API_URL.verifyOTPForgotPwd, payload);
    }

    /**
     * Change password
     * @param payload
     */
    changePwd(payload: any) {
        return this.post<HttpRequest<AuthResponse>>(API_URL.changePwd, payload);
    }

    /**
     * Login
     * @param url
     * @param payload
     */
    login(url: string, payload: LoginModel): Observable<AuthResponse> {
        return this.post<AuthResponse>(API_URL.LOGIN, payload);
    }

    /**
     * Logout
     * @param url
     */
    logout(url: string): Observable<AuthResponse> {
        return this.post<AuthResponse>(url, {});
    }

    /**
     * Submit registration form
     * @param payload
     */
    register(payload: RegisterModel): Observable<UserModel> {
        return this.post<UserModel>(API_URL.REGISTER, payload);
    }

    /**
     * Verify registration OTP
     * @param payload
     */
    verifyOTP(payload: VerifyOTPModel): Observable<AuthResponse> {
        return this.post<AuthResponse>(API_URL.verifyOTP, payload);
    }

    refreshToken(
        url: string,
        payload: { refreshToken: string }
    ): Observable<AuthResponse> {
        return this.post<AuthResponse>(url, payload);
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    /*
     * Get user info from storage.
     *
     * */
    getUserInfo() {
        if (this.userLogin$.value) {
            return this.userLogin$ as Observable<UserModel>;
        } else {
            const user = this.storageService.get(KEY.USER_LOGIN);
            this.setUserInfo(user);

            return this.userLogin$ as Observable<UserModel>;
        }
    }

    setUserInfo(data) {
        this.userLogin$.next(data);
    }

    /**
     * @function: Check if login: true-false
     */
    isLogin(): boolean {
        return this.storageService.get(KEY.USER_LOGIN);
    }

    /**
     * @function: Get current user login
     */
    currentUserInfo(url: string): Observable<UserModel> {
        return this.get<UserModel>(url).pipe(map((data: any) => data?.item));
    }

    resendOTP(payload: ResendOTP): Observable<AuthResponse> {
        return this.post<AuthResponse>(API_URL.RESEND_OTP, payload);
    }
}
