import { Injectable } from "@angular/core";
import { BaseHttpRequest } from "@services/http/base-http-request.service";
import { RegisterNotification } from "@models/user/notification.model";
import { catchError, from, Observable } from "rxjs";
import { deleteToken, getMessaging, getToken, Messaging, } from "firebase/messaging"; // Thư viện Firebase-NPM cung cấp.
import { environment } from "environments/environment";
import { PATH_MESSAGE } from "@constants/backend-url.constants";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { onMessage } from "@firebase/messaging";
import { NOTIFICATION_TYPES } from "@constants/notification.contants";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    notify = {
        body: '',
        icon: 'https://hoaland.com/favicon.ico',
        badge: "https://hoaland.com/favicon.ico",
        timestamp: 10,
        image: "https://hoaland.com/favicon.ico"
    };
    private messaging: Messaging = getMessaging();
    private option: any = { vapidKey: environment.firebaseConfig.vapidKey };
    private hand

    constructor(
        private http: BaseHttpRequest,
        private storageService: TokenStorageService
    ) {
        this.getNotification();
    }

    public getNewToken(): void {
        if (this.isExistsToken() || !this.isAccountLogin()) return;

        getToken(this.messaging, this.option)
            .then((currentToken) => {
                // console.log("Token do Firebase tao ra :", currentToken);
                if (currentToken) {
                    this.registerNotification({
                        token: currentToken,
                        device: window.navigator.userAgent,
                        system: 2,
                    })
                        .pipe(
                            catchError((err) => {
                                throw err;
                            })
                        )
                        .subscribe((_) => {
                            // lưu lại vào storage.
                            this.storageService.set(
                                "FIRE_BASE_TOKEN",
                                currentToken
                            );
                        });
                }
            })
            .catch((err) => {
                console.error("Lỗi khi lấy token.", err);
            });
    }

    public deleteToken(): Observable<any> {
        return from(deleteToken(this.messaging));
    }

    public registerNotification(
        payload: RegisterNotification
    ): Observable<any> {
        return this.http.post(PATH_MESSAGE.REGISTER_NOTIFICATION, payload);
    }

    // sẽ chay khi in-app và nhận được notification.
    private getNotification() {
        onMessage(this.messaging, (event) => {
            const { notification, data } = event;
            const notificationTitle = notification?.title;
            const notify = new Notification(notificationTitle, { ...this.notify, body: notification?.body });

            this.handleNotification(notify, data);
        })
    }

    private handleNotification(notify: Notification, metadata: any) {
        notify.addEventListener('click', () => {
            switch (metadata?.type?.toUpperCase()) {
                case NOTIFICATION_TYPES.EMAIL_REAL_ESTATES:
                    window.open("https://mail.google.com/mail/u/0/#inbox");
                    break;
                case NOTIFICATION_TYPES.DETAIL_REAL_ESTATE:
                    window.open();
                    break;
                default:
                    window.open("/");
                    break;
            }
        })
    }

    private isExistsToken(): boolean {
        return this.storageService.get("FIRE_BASE_TOKEN") || null;
    }

    private isAccountLogin(): boolean {
        return this.storageService.get("JWT_TOKEN") || null;
    }
}
