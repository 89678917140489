import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { CompareRealEstateService } from "@services/common/compare-real-estate.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";
import { BookOrderComponent } from "app/public/modules/book-order/book-order.component";
import { UserModel } from "@models/user/user.model";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { KEY } from "@constants/backend-url.constants";
import { ContactConsultantComponent } from "app/public/modules/contact-consultant/contact-consultant.component";
import {
    ModalSimilarRealEstatesComponent,
} from "app/public/modules/modal-similar-real-estates/modal-similar-real-estates.component";
import { prefillComparationUrl } from "@utils/prefill-comparation-url";
import { MatTooltip } from "@angular/material/tooltip";
import { RealEstate } from "@models/land/real-estate.model";

@Component({
    selector: "app-compare-real-estates-modal",
    templateUrl: "./compare-real-estates-modal.component.html",
    styleUrls: ["./compare-real-estates-modal.component.scss"],
})
export class CompareRealEstatesModalComponent implements OnInit {
    @ViewChild("tooltip") tooltip: MatTooltip;
    public realEstates$: Observable<RealEstate[]>;
    public isFixed: boolean = false;
    public numOfRow = [0, 1, 2];
    public numOfRowMobile = [0, 1];
    public isMobileScreen: boolean;
    public user: UserModel;

    constructor(
        private compareService: CompareRealEstateService,
        private mediaMatcher: MediaMatcher,
        private matDialog: MatDialog,
        private storageService: TokenStorageService,
        private renderer: Renderer2,
    ) {
        this.isMobileScreen =
            this.mediaMatcher.matchMedia("(max-width: 768px)").matches;
        this.user = this.storageService.get(KEY.USER_LOGIN || "");
    }

    ngOnInit() {
        this.realEstates$ = this.compareService.on();
        this.onScrollTop();
    }

    public onClickAddRealEstate() {
        this.matDialog.open(ModalSimilarRealEstatesComponent, {
            width: "1248px",
            maxHeight: "100vh",
            maxWidth: "100vw",
            panelClass: "modal-similar-real-estates",
        });
    }

    public onCloseModal() {
        this.matDialog.closeAll();
    }

    public onClickDeleteRealEstate(id: number) {
        this.compareService.remove(id);
    }

    public onClickShare() {
        this.realEstates$.subscribe((val) => {
            const ids = val.map((v) => v.id);
            navigator.clipboard.writeText(prefillComparationUrl(ids)).then();
            this.tooltip.show();
        });
    }

    public openModalContactConsultant(landId: string | number) {
        this.matDialog.open(ContactConsultantComponent, {
            panelClass: "modal-panel",
            data: {
                landId: landId,
                user: this.user?.username,
            },
        });
    }

    public openModalBookOrder(landId: string | number) {
        this.matDialog.open(BookOrderComponent, {
            panelClass: "modal-panel",
            data: {
                landId: landId,
                user: this.user?.username,
            },
        });
    }

    public onScrollTop() {
        const compareModal = document.querySelector(".compare-real-estates-modal");

        this.renderer.listen(compareModal, "scroll", () => {
            compareModal.scrollTop > 500
                ? (this.isFixed = true)
                : (this.isFixed = false);
            this.tooltip.hide();
        });
    }
}
