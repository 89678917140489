import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    WidgetInfoRealEstateComponent
} from "app/public/modules/widget-info/widget-info-real-estate/widget-info-real-estate.component";
import { MatTooltipModule } from "@angular/material/tooltip";


const COMPONENTS = [
    WidgetInfoRealEstateComponent
]

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        MatTooltipModule
    ]
})
export class WidgetInfoModule {
}
