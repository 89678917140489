import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from "@angular/core";

@Component({
    selector: "app-input-otp",
    styleUrls: ["./input-otp.component.scss"],
    templateUrl: "./input-otp.component.html",
})
export class InputOtpComponent {
    @Input() email: String;
    @Output() completedEntered = new EventEmitter();
    @ViewChild("ngOtpInput") ngOtpInputRef: any;
    private otpLength: number = 6;

    @Input() set length(value: number) {
        if (value) this.otpLength = value;
    }

    get lengthOtp(): number {
        return this.otpLength;
    }

    onOtpChange(event: any): void {
        if (event.length === this.lengthOtp) {
            this.completedEntered.emit(event);
            this.ngOtpInputRef.setValue("");
        }
    }
}
