import { isPlatformBrowser } from "@angular/common";

export function ScrollToTop(platformId) {
  setTimeout(() => {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        if (isPlatformBrowser(platformId)) {
          window.scrollTo(0, 0);
        }
      });
    }
  }, 100);
}
