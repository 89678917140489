import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imgSizeAvatar",
})
export class ImgSizeAvatarPipe implements PipeTransform {
  public readonly IMAGE_DEFAULT = "./assets/images/realEstate/default.webp";
  public AVATAR_SIZE = "40x40";

  transform(url: string): string {
    if (url) {
      return url.replace("0x0", this.AVATAR_SIZE);
    }
    return this.IMAGE_DEFAULT;
  }
}
