import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "addMeterCharacter",
})
export class AddMeterCharacterPipe implements PipeTransform {
  transform(value: unknown): string {
    return value ? value + " m" : "--";
  }
}
