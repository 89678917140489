import { FormControl, FormGroup } from "@angular/forms";

export function emailValidator(control: FormControl): { [key: string]: any } {
  const emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export function phoneValidator(control: FormControl): { [key: string]: any } {
  const emailRegexp = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidPhoneNumber: true };
  }
}

export function matchingPasswords(
  passwordKey: string,
  passwordConfirmationKey: string
) {
  return (group: FormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true });
    }
  };
}
