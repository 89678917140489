import { AfterViewInit, Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { API_URL, KEY } from "@constants/backend-url.constants";
import { ERROR_MESSAGE } from "@constants/common";
import { LoginModel, RegisterModel } from "@models/auth/auth.model";
import { AuthService } from "@services/auth/auth.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { ToastrService } from "ngx-toastr";
import { catchError, map, Subject, takeUntil, tap, throwError } from "rxjs";
import { OtpRegisterModalComponent } from "../otp-register-modal/otp-register-modal.component";
import { UserModel } from "@models/user/user.model";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-auth-modal",
    templateUrl: "./auth-modal.component.html",
    styleUrls: ["./auth-modal.component.scss"],
})
export class AuthModalComponent implements AfterViewInit {
    public ERROR_MESSAGE = ERROR_MESSAGE;
    private subscription$ = new Subject();
    public tab = 0;

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        private storageService: TokenStorageService,
        private router: Router,
        private toastrService: ToastrService,
        private dialogRef: MatDialogRef<AuthModalComponent>,
    ) {
    }

    ngAfterViewInit(): void {
        this.subscription$.next(null);
        this.subscription$.complete();
    }

    public selectedTabChange(event: MatTabChangeEvent) {
        this.tab = event.index;
    }

    public onCloseModal() {
        this.dialog.closeAll();
    }

    public onSubmitFormLogin(loginForm: FormGroup) {
        if (loginForm.invalid) {
            loginForm.markAllAsTouched();
            return;
        }

        const payload: LoginModel = loginForm.getRawValue();
        this.authService
            .login(API_URL.LOGIN, payload)
            .pipe(
                takeUntil(this.subscription$),
                map((data) => data),
                tap(() => this.handleLoginSuccess()),
                catchError((error) => {
                    this.handleLoginFailed();
                    return error;
                }),
            )
            .subscribe();
    }

    public onSubmitFormRegister(registerForm: FormGroup) {
        if (registerForm.invalid) {
            registerForm.markAllAsTouched();
            return;
        }

        const {
            password,
            confirmPassword,
            email,
            reCaptcha,
            name,
            phone,
            type,
            idNumber = "",
            businessName = "",
        } = registerForm.getRawValue();

        const payload: RegisterModel = {
            username: phone,
            password,
            confirmPassword,
            email,
            reCaptcha,
            name: name || "",
            businessName: businessName,
            phone,
            type,
            idNumber: idNumber,
        };
        this.authService
            .register(payload)
            .pipe(
                takeUntil(this.subscription$),
                catchError(({ error }) => {
                    const { errors } = error;

                    this.showError(errors);
                    return throwError(error);
                }),
            )
            .subscribe((data: any) => {
                const { item } = data;
                this.storageService.set(KEY.USER_LOGIN, item);
                this.dialogRef.close();
                this.dialog.open(OtpRegisterModalComponent, {
                    panelClass: "modal-panel",
                    disableClose: true,
                });
            });
    }

    private handleLoginSuccess() {
        this.toastrService.clear();
        this.onCloseModal();
        this.toastrService.success("Bạn đã đăng nhập thành công!", "", {
            toastClass: "toast-customize ngx-toastr",
        });

        this.getCurrentUserLogin();
    }

    public handleLoginFailed() {
        this.toastrService.error("SĐT hoặc Mật khẩu không đúng!", "", {
            toastClass: "toast-customize ngx-toastr",
        });
    }

    private getCurrentUserLogin() {
        this.authService.currentUserInfo(
            API_URL.getCurrentUserLogin,
        ).pipe(
            tap((user) => this.updateUserInfo(user)),
        ).subscribe(() => this.handleRedirectAfterLogin());
    }

    private updateUserInfo(user: UserModel) {
        this.storageService.set(KEY.USER_LOGIN, { ...user });

        this.authService.setUserInfo({ ...user });
        this.storageService.set(KEY.SEARCH_HISTORY, []);
    }

    private handleRedirectAfterLogin() {
        const redirectUrl = this.storageService.get(KEY.REDIRECT_URL);
        if (redirectUrl) {
            const queryParamsIndex = redirectUrl?.indexOf("?");
            let queryParams = {};
            if (queryParamsIndex !== -1) {
                const queryString = redirectUrl?.substring(
                    queryParamsIndex + 1,
                );
                queryParams = this.parseQueryParams(queryString);
            }
            this.router.navigate([APP_PAGES.SEARCH], {
                queryParams: queryParams,
                replaceUrl: true,
            }).then();
            this.storageService.delete(KEY.REDIRECT_URL);
        }
    }

    private showError(errors) {
        errors?.forEach(({ code }) => {
            const message =
                this.ERROR_MESSAGE[code] || this.ERROR_MESSAGE.UNDEFINED;
            this.toastrService.error(message);
        });
    }

    private parseQueryParams(queryString: string) {
        return queryString
            ?.split("&")
            .map((param) => param.split("="))
            .reduce((values, [key, value]) => {
                values[key] = decodeURIComponent(value);
                return values;
            }, {});
    }
}
