import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { IndividualConfig } from "ngx-toastr/toastr/toastr-config";

@Injectable({
  providedIn: "root",
})
export class NotifyService {
  configs: IndividualConfig = {
    closeButton: true,
    disableTimeOut: undefined,
    easeTime: undefined,
    easing: "",
    enableHtml: false,
    extendedTimeOut: 0,
    messageClass: "",
    newestOnTop: false,
    onActivateTick: false,
    payload: undefined,
    positionClass: "",
    progressAnimation: undefined,
    progressBar: false,
    tapToDismiss: false,
    timeOut: 0,
    titleClass: "",
    toastClass: "",
  };

  constructor(private toastr: ToastrService) {}

  success(msg: string, title?: string) {
    this.toastr.success(msg, title);
  }

  waring(msg: string, title?: string) {
    this.toastr.warning(msg, title);
  }

  error(msg: string, title?: string) {
    this.toastr.error(msg, title);
  }

  info(msg: string, title?: string) {
    this.toastr.info(msg, title);
  }
}
