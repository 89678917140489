import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactService } from "@services/crm/contact.service";
import { CONTACT_TYPE } from "@constants/common";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { UserModel } from "@models/user/user.model";
import { KEY } from "@constants/backend-url.constants";
import { NotifyService } from "@services/common/notify.service";

@Component({
    selector: "app-contact-consultant",
    templateUrl: "./contact-consultant.component.html",
    styleUrls: ["./contact-consultant.component.scss"],
})
export class ContactConsultantComponent {
    private infoUser: UserModel = this.storageService.get(KEY.USER_LOGIN);
    public contactForm: FormGroup = this.initForm();

    constructor(
        public storageService: TokenStorageService,
        public dialogRef: MatDialogRef<ContactConsultantComponent>,
        private fb: FormBuilder,
        private contactService: ContactService,
        private notify: NotifyService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }

    get controls() {
        return this.contactForm.controls;
    }

    onSubmit() {
        if (this.contactForm.valid) {
            const rawData = this.contactForm.getRawValue();
            this.contactService.addContact(rawData).subscribe((_) => {
                this.notify.success("Cảm ơn bạn đã quan tâm đến sản phẩm");
                this.closeSelf();
            });
        }
    }

    closeSelf() {
        this.dialogRef.close();
    }

    private initForm(): FormGroup {
        return this.fb.group({
            submitter: this.fb.control(this.data?.user),
            fullName: this.fb.control(`${this.infoUser?.fullName}`, [
                Validators.required,
            ]),
            phone: this.fb.control(`${this.infoUser?.phone}`, [Validators.required]),
            email: this.fb.control(`${this.infoUser?.email}`, [Validators.required]),
            referenceId: this.fb.control(this.data?.landId),
            type: this.fb.control(CONTACT_TYPE.CONSULTANT),
            content: this.fb.control("", [Validators.required]),
        });
    }
}
