import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { phoneValidator } from "@utils/app-validators";
import { AuthModalComponent } from "../auth-modal/auth-modal.component";
import { ResetPwModalComponent } from "../reset-pw-modal/reset-pw-modal.component";

@Component({
    selector: "app-login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent {
    @Output() submitLoginEvent = new EventEmitter();

    public loginForm: FormGroup = this.initForm();

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<AuthModalComponent>,
        private fb: FormBuilder,
        public storageService: TokenStorageService
    ) {}

    get controls() {
        return this.loginForm.controls;
    }

    private initForm(): FormGroup {
        return this.fb.group({
            username: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10),
                    phoneValidator,
                ]),
            ],
            password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(6),
                ]),
            ],
        });
    }

    private lockScroll() {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollLeft =
            window.scrollX || document.documentElement.scrollLeft;

        window.onscroll = () => window.scrollTo(scrollLeft, scrollTop);
    }

    private unlockScroll() {
        window.onscroll = () => null;
    }

    public openResetPwModal() {
        const modalRef = this.dialog.open(ResetPwModalComponent, {
            panelClass: "modal-panel",
            disableClose: true
        });
        this.dialogRef.close();
        this.lockScroll();

        modalRef.afterClosed().subscribe(() => this.unlockScroll());
    }

    public onSubmitForm() {
        this.submitLoginEvent.emit(this.loginForm);
    }
}
