import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { LAND_PURPOSE } from "@constants/common";

@Directive({
    selector: "[badgeStatus]",
})
export class BadgeStatusDirective implements AfterViewInit {

    @Input("badgeStatus") badgeStatus: string;

    constructor(private elRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.elRef.nativeElement.style.backgroundColor = this.getStatusBgColor(this.badgeStatus);
    }

    public getStatusBgColor(status: string) {
        switch (status) {
            case LAND_PURPOSE.FOR_SELL:
                return "#D4A518";
            case LAND_PURPOSE.FOR_RENT:
                return "rgba(2, 89, 230, 1)";
            default:
                return "rgba(2, 89, 230, 1)";
        }
    }
}
