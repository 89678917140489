export const APP_PAGES = {
    DEFAULT: "/",
    HOME: "trang-chu",
    LOGIN: "dang-nhap",
    SEARCH: "tim-kiem",
    ABOUT_US: "ve-chung-toi",
    FORGOT_PWD: "quen-mat-khau",
    COMPARE_REAL_ESTATE: "so-sanh-bat-dong-san",
    FAVORITE_REAL_ESTATE: "bat-dong-san-yeu-thich",
    PROFILE: "ho-so",
    CHANGE_PASSWORD: "doi-mat-khau",
    USER: "nguoi-dung",
    HISTORY: "lich-su"
};
