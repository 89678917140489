import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CategoryService } from "@services/category/category.service";
import { TYPE_REAL_ESTATE } from "@constants/common";

@Pipe({
  name: "landType",
})
export class LandTypePipe implements PipeTransform {
  constructor(private categoryService: CategoryService) {}

  transform(value: string | number = "1"): Observable<any> {
    return this.categoryService.getAllType().pipe(
      map((data) => {
        // List vị trí theo độ dài tên
        const types = TYPE_REAL_ESTATE;
        types.sort((a, b) => {
          return b.title.length - a.title.length;
        });

        const d = types.find((d) => d.id === value?.toString());
        return d?.title || "--";
      })
    );
  }
}
