import { Component, Input } from "@angular/core";
import { RealEstate } from "@models/land/real-estate.model";

@Component({
  selector: "app-item-detail-car-park",
  templateUrl: "./item-detail-car-park.component.html",
})
export class ItemDetailCarParkComponent {
  @Input() realEstate: RealEstate;

  constructor() {}
}
