import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmModalComponent } from "app/public/modules/confirm-modal/confirm-modal.component";
import {
    EditSavedSearchModalComponent,
} from "app/public/modules/edit-saved-search-modal/edit-saved-search-modal.component";
import { KEY } from "@constants/backend-url.constants";
import { DetectVideoFile } from "@helpers/detect-media-type";
import { RealEstate } from "@models/land/real-estate.model";
import { HistorySearchingUserModel } from "@models/user/history-searching-user.model";
import { NotifyService } from "@services/common/notify.service";
import { HistorySearchingOfCustomerService } from "@services/customer/history-searching-of-customer.service";
import { FavoriteRealEstateService } from "@services/favorite-real-estate/favorite-real-estate.service";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { catchError, map, Observable, Subscription, tap, throwError } from "rxjs";
import { APP_PAGES } from "@constants/app-url.constants";

@Component({
    selector: "app-saved-searches",
    templateUrl: "./saved-searches.component.html",
    styleUrls: ["./saved-searches.component.scss"],
})
export class SavedSearchesComponent implements OnInit, OnDestroy {
    public tab = 0;
    public totalItems: number;
    public limit = 8;
    public page = 0;
    public listRecentSearch = ["Hà Nội", "Đà Nẵng", "Hải Phòng"];
    public APP_PAGES_ROUTER = APP_PAGES;

    public DetectVideoFile = DetectVideoFile;
    public realEstatesFavorite$: Observable<RealEstate[]>;
    public saveSearchRealEstates: HistorySearchingUserModel[];
    public subscription$: Subscription;

    constructor(
        private dialog: MatDialog,
        private favoriteService: FavoriteRealEstateService,
        private realEstateService: HttpRealEstateService,
        private localStorageService: TokenStorageService,
        private historySearchingService: HistorySearchingOfCustomerService,
        private router: Router,
        public route: ActivatedRoute,
        private notifyService: NotifyService,
    ) {
        this.tab = this.getTabIndex(route.snapshot.fragment);
    }

    ngOnInit() {
        this.getRealEstateFavorite();
        this.getHistorySearchList();
    }

    public onOpenEditModal(item: HistorySearchingUserModel) {
        const modalRef = this.dialog.open(EditSavedSearchModalComponent, {
            maxHeight: "100vh",
            data: {
                searchHistory: item,
            },
        });

        this.subscription$ = modalRef.componentInstance.listChanged.subscribe(
            () => this.getHistorySearchList(),
        );
    }

    public openDeleteModalFavorite(id: string | number) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            maxWidth: "360px",
            maxHeight: "100vh",
        });

        dialogRef.componentInstance.title = "Xoá BĐS";
        dialogRef.componentInstance.content =
            "Bạn có chắc chắn xoá BĐS này ra khỏi danh sách BĐS yêu thích không?";

        dialogRef.afterClosed().subscribe((state: boolean) => {
            state && this.removeFavoriteRealEstate(id);
        });
    }

    public removeFavoriteRealEstate(id: number | string): void {
        this.realEstateService
            .followProduct(id)
            .subscribe((_) => this.getRealEstateFavorite());
    }

    public selectedTabChange($event) {
        this.tab = $event.index;
        const fragments = ["search", "saved", "favorite"];
        const fragment = fragments[this.tab] || "search";
        this.router.navigate([], {
            fragment: fragment,
            queryParamsHandling: "preserve",
        }).then();
    }

    public getRealEstateFavorite(page?: number): void {
        this.realEstatesFavorite$ = this.favoriteService
            .getRealEstateFavorite({
                limit: this.limit,
                page: page || this.page,
            })
            .pipe(
                tap((data) => {
                    this.totalItems = data.totalItems;
                }),
                map((realEstate) => realEstate.data),
            );
    }

    private getHistorySearchList() {
        const userId = this.localStorageService.get(KEY.USER_LOGIN).id;
        this.historySearchingService
            .getSearchingRealEstateList(userId)
            .subscribe((res) => {
                this.saveSearchRealEstates = res.data;
            });
    }

    public openDeleteModalHistorySearch(hash: string) {
        const modalRef = this.dialog.open(ConfirmModalComponent, {
            maxWidth: "360px",
            maxHeight: "100vh",
        });

        modalRef.componentInstance.title = "Xoá lịch sử";
        modalRef.componentInstance.content =
            "Bạn có chắc chắn xoá lịch sử tìm kiếm này ra khỏi danh sách không?";

        modalRef.afterClosed().subscribe((state: boolean) => {
            state && this.onDeleteHistorySearch(hash);
        });
    }

    public onDeleteHistorySearch(hash: string) {
        const userId = this.localStorageService.get(KEY.USER_LOGIN).id;

        this.historySearchingService
            .deleteSaveSearchingRealEstate(userId, hash)
            .pipe(
                catchError((error) => {
                    this.notifyService.waring("Xoá lịch sử tìm kiếm thất bại!");
                    return throwError(() => error);
                }),
            )
            .subscribe((_) => {
                this.notifyService.success("Xoá lịch sử tìm kiếm thành công!");
                this.getHistorySearchList();
            });
    }

    public onRedirectHistorySearch(item: any) {
        const { centerLat, centerLng, radius, ...params } = item.params;
        this.router.navigate([APP_PAGES.SEARCH], { queryParams: params }).then();
        this.localStorageService.set("SEARCH_HISTORY_ITEM", item);
    }

    ngOnDestroy(): void {
        this.subscription$ ? this.subscription$.unsubscribe() : null;
    }

    private getTabIndex(hashtag: string): number {
        switch (hashtag) {
            case "search":
                return 0;
            case "saved":
                return 1;
            case "favorite":
                return 2;
            default:
                return 0;
        }
    }

    protected readonly APP_PAGES = APP_PAGES;
}
