import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ItemDetailCarParkComponent
} from "app/public/modules/real-estate-info/item-detail-car-park/item-detail-car-park.component";
import {
    ItemDetailExteriorComponent
} from "app/public/modules/real-estate-info/item-detail-exterior/item-detail-exterior.component";
import {
    ItemDetailFurnitureComponent
} from "app/public/modules/real-estate-info/item-detail-furniture/item-detail-furniture.component";
import {
    ItemDetailOtherInfoComponent
} from "app/public/modules/real-estate-info/item-detail-other-info/item-detail-other-info.component";
import {
    ItemDetailOverviewComponent
} from "app/public/modules/real-estate-info/item-detail-overview/item-detail-overview.component";
import { ItemDetailSizeComponent } from "app/public/modules/real-estate-info/item-detail-size/item-detail-size.component";
import { LandPurposeSelect } from "app/public/modules/real-estate-info/land-purpose-select/land-purpose-select.component";
import { LandTypesSelect } from "app/public/modules/real-estate-info/land-type-select/land-types-select.component";
import { LandTypesSelectV2 } from "app/public/modules/real-estate-info/land-type-select-v2/land-types-select-v2.component";
import { SelectRoomGroupComponent } from "app/public/modules/real-estate-info/select-room-group/select-room-group.component";
import { FlexModule } from "@angular/flex-layout";
import { PipesModule } from "@pipes/pipes.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { DirectivesModule } from "@directives/directives.module";
import { MatButtonModule } from "@angular/material/button";

const COMPONENTS = [
    ItemDetailCarParkComponent,
    ItemDetailExteriorComponent,
    ItemDetailFurnitureComponent,
    ItemDetailOtherInfoComponent,
    ItemDetailOverviewComponent,
    ItemDetailSizeComponent,
    LandPurposeSelect,
    LandTypesSelect,
    LandTypesSelectV2,
    SelectRoomGroupComponent
]

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [
        CommonModule,
        FlexModule,
        PipesModule,
        OverlayModule,
        MatRadioModule,
        FormsModule,
        MatCheckboxModule,
        MatExpansionModule,
        DirectivesModule,
        MatButtonModule
    ]
})
export class RealEstateInfoModule {
}
