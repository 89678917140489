import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from "@angular/router";
import { KEY } from "@constants/backend-url.constants";
import { USER_STATUS } from "@constants/business.constants";
import { UserModel } from "@models/user/user.model";
import { OtpRegisterModalComponent } from "@pages/auth/otp-register-modal/otp-register-modal.component";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { Observable } from "rxjs";
import { APP_PAGES } from "@constants/app-url.constants";

@Injectable({
    providedIn: "root",
})
export class PreventUserUnactiveGuard implements CanActivate {
    private user: UserModel = this.storageService.get(KEY.USER_LOGIN);

    constructor(
        public storageService: TokenStorageService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.user && this.user.status === USER_STATUS.PENDING_OTP) {
            this.router.navigate([APP_PAGES.DEFAULT]).then();
            this.dialog.open(OtpRegisterModalComponent, {
                panelClass: "modal-panel",
                disableClose: true,
            });
        }
        return true;
    }
}
