import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    first,
    Observable,
    of,
    pairwise,
    Subject,
    switchMap,
    takeUntil,
} from "rxjs";
import { MapsAPILoader } from "@agm/core";

import { MatDialog } from "@angular/material/dialog";
import { GetShortAddressPipe } from "@pipes/get-short-address.pipe";
import { HttpRealEstateService } from "@services/lands/http-real-estate.service";
import { KEY } from "@constants/backend-url.constants";
import { ActivatedRoute, Router } from "@angular/router";
import { PolygonAreaService } from "@services/google-maps/polygon-area.service";
import { GoogleMapsService } from "@services/google-maps/google-maps.service";
import { AuthService } from "@services/auth/auth.service";
import { FavoriteRealEstateService } from "@services/favorite-real-estate/favorite-real-estate.service";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { LAND_PURPOSE } from "@constants/common";
import { UserModel } from "@models/user/user.model";
import { AREA_FRONT, PRICE_FOR_RENT, PRICE_FOR_SELL, SQUARE } from "@constants/real-estate-search.constants";
import { HistoryUserService } from "@services/history-user.service";
import { MapRealEstateState } from "@services/lands/map-real-estate.state";
import { RealEstate } from "@models/land/real-estate.model";
import { APP_PAGES } from "@constants/app-url.constants";
import { RegionSuggestionService } from "@services/resource/region-suggestion.service";
import { LANGUAGES } from "@constants/language.constant";
import { EVENT_BUS_EVENTS, EventBusService } from "@services/common/event-bus.service";

@Component({
    selector: "app-input-search-suggestion",
    templateUrl: "./input-search-suggestion.component.html",
    styleUrls: ["./input-search-suggestion.component.scss"],
    providers: [
        GoogleMapsService,
        GetShortAddressPipe,
        RegionSuggestionService,
    ],
})
export class InputSearchSuggestionComponent
    implements OnDestroy, OnChanges, OnInit {
    public readonly APP_LANGUAGE = LANGUAGES;
    public PRICE_FOR_RENT = PRICE_FOR_RENT;
    public PRICE_FOR_SELL = PRICE_FOR_SELL;
    public AREA_FRONT = AREA_FRONT;
    protected readonly LAND_PURPOSE = LAND_PURPOSE;
    protected readonly SQUARE = SQUARE;

    public userInfo$: Observable<UserModel> = this.authService.getUserInfo(); // Khai báo có thể lắng nghe sự kiện
    public searchText = new FormControl("");
    public subscriptions$ = new Subject();
    public pause$ = new BehaviorSubject<boolean>(true);
    public placeHolderSuggestion: string =
        "Mời bạn nhập địa chỉ muốn tìm kiếm...";
    public purpose = LAND_PURPOSE.FOR_RENT;
    public listSearched$: Observable<any> = this.historyService.get(
        KEY.SEARCH_HISTORY,
    );

    @Input() layout: "small" | "normal" = "normal";
    @Input() size: number = 18;
    @Input() searchForm: FormGroup = new FormGroup({});
    @Input() preventFireEvent: boolean = false; // Chặn fire event đến các service khác.
    @Input() havePurpose: boolean = true; // Kiểm tra xem cần mục đích hay không
    @Input() hideOnSelect: boolean = true;
    @Input() isShowFilterBtn: boolean = true;

    @Output() selected = new EventEmitter();

    @ViewChild("menuForMobile") menuForMobile: TemplateRef<any>;
    @ViewChild("suggestionSearch")
    public searchElementRef: ElementRef;
    public totalItems: any;
    public searchItems: any[] = [];
    public addressSuggestions$: Observable<any> =
        this.regionSuggestionService.items;
    public isFetchData$: Observable<any> =
        this.regionSuggestionService.isFetchData;
    public isVisible: boolean;
    public priceStart: number[];
    public priceEnd: number[];
    public unit: string;
    public filterDialog: any;

    constructor(
        public storageService: TokenStorageService,
        private careLocalService: FavoriteRealEstateService,
        private activeRoute: ActivatedRoute,
        private authService: AuthService,
        private googleMapService: GoogleMapsService,
        private mapsAPILoader: MapsAPILoader,
        private polygonService: PolygonAreaService,
        private router: Router,
        private landService: HttpRealEstateService,
        private shortAddressPipe: GetShortAddressPipe,
        public dialog: MatDialog,
        private historyService: HistoryUserService,
        private cdr: ChangeDetectorRef,
        private regionSuggestionService: RegionSuggestionService,
        private mapState: MapRealEstateState,
        private eventBus: EventBusService,
    ) {
        this.userInfo$ = this.authService.getUserInfo(); // Lấy giá trị từ lắng nghe sự kiện bên service authen
        this.listSearched$ = this.historyService.get(KEY.SEARCH_HISTORY);
    }

    ngOnInit(): void {
        this.observerUserInfo();
        this.getValueFromHomeInput();
    }

    ngOnDestroy(): void {
        this.subscriptions$.next(null);
        this.subscriptions$.complete();
    }

    private observerUserInfo() {
        combineLatest([this.mapsAPILoader.load()])
            .pipe()
            .subscribe((_) => {
                this.observerInputSearchChange();
                this.userInfo$.subscribe((user) => {
                    if (user) this.getDataRealEstateLike();
                });
                this.scriptRerender();
            });
    }

    public getControl(name: string) {
        return this.searchForm.get(name) as FormControl;
    }

    /**
     * @description Event ấn nút enter search.
     */
    public onPressToSearch() {
        this.searchForm.patchValue({
            purpose: this.purpose,
        });
        this.searchElementRef.nativeElement.blur();
    }

    public onClickRedirectDetail(data: any): void {
        this.handleRedirectDetail(data);
    }

    public clearSearch(): void {
        this.searchText.reset();
        this.searchForm.patchValue({
            address: "",
            city: "",
            town: "",
            street: "",
            district: "",
        });
        this.mapState.boundaries = null;
    }

    public onClickRedirectSearchArea(data: any): void {
        this.redirectToSearchArea(data);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchForm.currentValue) {
            this.updateAddressControlToInputSearch();
            this.observeInputSearchChanged();
        }
    }

    public openFilter(): void {
        this.filterDialog = this.dialog.open(this.menuForMobile, {
            minWidth: "100vw",
            minHeight: "100vh",
            height: "100%",
            panelClass: ["panel-class"],
        });
    }

    private updateAddressControlToInputSearch(): void {
        const rawValue = this.searchForm.getRawValue();
        const address = rawValue.address;
        this.searchText.patchValue(address);
    }

    // public getCurrentLocation() {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             let geocoder = new google.maps.Geocoder();
    //             let request = {
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude,
    //             };
    //             geocoder.geocode({ location: request }, (results, status) => {
    //                 if (status == google.maps.GeocoderStatus.OK) {
    //                     if (results[6] != null) {
    //                         let address = results[6].formatted_address;
    //                         this.searchText.setValue(address); // patch value vào ô input
    //                         this.router
    //                             .navigate([APP_PAGES.SEARCH], {
    //                                 relativeTo: this.activeRoute,
    //                                 queryParams: { address }, // Chuyển hướng param
    //                             })
    //                             .then();
    //                     }
    //                 }
    //             });
    //         });
    //     }
    // }

    public showSearchSuggestion(): void {
        // @TODO
        this.isVisible = true;
        this.pause$.next(false);
        this.eventBus.emit({ name: EVENT_BUS_EVENTS.FOCUS_INPUT_SEARCH, value: true });
    }

    public hideSearchSuggestion(): void {
        this.isVisible = false;
        this.pause$.next(true);
        this.eventBus.emit({ name: EVENT_BUS_EVENTS.FOCUS_INPUT_SEARCH, value: false });
    }

    public onClickChangePurpose(purpose: string): void {
        this.purpose = purpose;
        this.searchForm.reset();
        this.handleSelectedPurpose(purpose);

        this.cdr.detectChanges();
    }

    private handleRedirectDetail(realEstate: RealEstate) {
        this.shortAddressPipe
            .transform(realEstate?.address)
            .subscribe((address) => {
                this.router
                    .navigate([APP_PAGES.SEARCH], {
                        relativeTo: this.activeRoute,
                        queryParams: {
                            itemId: realEstate.id,
                            purpose: realEstate.purpose,
                            address,
                        },
                    })
                    .then();
                this.mapState.houseMarker = realEstate;
                this.setPointForDetail(realEstate);
            });
    }

    private setPointForDetail(realEstate: RealEstate) {
        const data = {
            lat: Number(realEstate.address?.latitude),
            lng: Number(realEstate.address?.longitude),
            type: ["street_address"],
        };

        this.polygonService.setLocation = data;
        this.polygonService.setZoom = data;
    }

    private scriptRerender() {
        const address = [
            "",
            "Mời bạn nhập địa chỉ muốn tìm kiếm...",
            "ex: Hoàn Kiếm, Hà Nội",
            "ex: T.P Hồ Chí Minh",
            "ex: T.P Thanh Hoá, Thanh Hoá",
        ];
        this.placeHolderSuggestion = address[Math.floor(Math.random() * 4) + 1];
    }

    // GET BẤT ĐỘNG SẢN ƯA THÍCH
    private getDataRealEstateLike() {
        this.careLocalService.getRealEstateFavorite(null).subscribe((data) => {
            this.totalItems = data.totalItems;
        });
    }

    private observerInputSearchChange(): void {
        this.searchText.valueChanges
            .pipe(
                takeUntil(this.subscriptions$),
                debounceTime(350),
                distinctUntilChanged(),
            )
            .subscribe((address: string) => {
                this.getListRealEstate(address);
                this.regionSuggestionService.region = address;
            });
    }

    private getListRealEstate(address: string) {
        const params = {
            address: address,
            purpose: this.searchForm.get("purpose").value,
        };
        this.landService.searchRealEstateName(params).subscribe((res: any) => {
            this.searchItems = [];
            if (res?.data.length) {
                const newData = res?.data.slice(0, 5);
                this.searchItems = this.searchItems.concat(newData);
            }
        });
    }

    public handleSelectedPurpose($event: any) {
        const { limit } = this.searchForm.getRawValue();
        this.searchForm.patchValue({
            purpose: $event,
            page: 0,
            limit,
        });
    }

    public onCloseModal(): void {
        this.filterDialog.close();
    }

    public onApplyFilter(): void {
        this.onCloseModal();
    }

    private observeInputSearchChanged(): void {
        this.searchForm.valueChanges
            .pipe(
                takeUntil(this.subscriptions$),
                pairwise(),
                switchMap(([pre, cur]) => {
                    const result = pre.address === cur.address ? null : cur;
                    return of(result);
                }),
            )
            .subscribe((data) => {
                if (data !== null) this.searchText.patchValue(data?.address);
            });
    }

    private fireEvent(data: any): void {
        if (this.preventFireEvent) return;

        const { location } = data;
        this.polygonService.setAreaPolygon = data;
        this.polygonService.setZoom = data;
        this.polygonService.setLocation = location;
    }

    private getValueFromHomeInput() {
        this.activeRoute.queryParams
            .pipe(first((params) => !!params.address))
            .subscribe((params) => {
                this.searchText.setValue(params.address);
            });
    }

    private redirectToSearchArea(data: any): void {
        // this.mapState.boundaries = null;
        // this.mapState.polygon = null;
        // this.mapState.houseMarker = null;

        this.selected.emit(data);

        // this.googleMapService
        //     .getAddressDetailFromText(data?.description)
        //     .then((res: any) => {
        //         this.fireEvent(res);
        //
        //         let address;
        //         if (res.street) {
        //             address = res.street + ", " + res.cityName;
        //         } else address = data?.description || "";
        //
        //         const values = {
        //             ...(res as Object),
        //             address,
        //         };
        //
        //         this.searchForm?.patchValue(values);
        //         this.searchText.setValue(data?.description || "");
        //
        //         this.selected.emit(this.searchForm.getRawValue());
        //     });
    }
}
