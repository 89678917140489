import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { BaseHttpRequest } from "../http/base-http-request.service";
import { map } from "rxjs/operators";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { environment } from "environments/environment";
import { API_URL, KEY } from "@constants/backend-url.constants";
import { STORAGE_DB_KEY } from "@constants/common";

@Injectable()
export class CategoryService {
  private cities$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private towns$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private districts$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private landTypes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private direction$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private floorMaterial$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  private street$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private parking$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private statusFurniture$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  private cateStreet$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private juridical$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private category$: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);
  private isLoading$: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );

  private neededUpdateDB = false;

  constructor(
    private baseHttpService: BaseHttpRequest,
    private dbService: NgxIndexedDBService,
    private tokenStorageService: TokenStorageService
  ) {
    this.getAllDataFromIndexDB();
    this.checkIndexDb();

    const _category$ = this.category$.asObservable();
    _category$
      .pipe(
        tap(({ items }) => {
          const _cities =
            items?.filter((d) => d.categoryCode === "Province") || null;
          _cities?.length && this.cities$.next(_cities);
        }),
        tap(({ items }) => {
          const _district =
            items?.filter((d) => d.categoryCode === "District") || null;
          _district?.length && this.districts$.next(_district);
        }),
        tap(({ items }) => {
          const _towns =
            items?.filter((d) => d.categoryCode === "Wards") || null;
          _towns?.length && this.towns$.next(_towns);
        }),
        tap(({ items }) => {
          const _category =
            items?.filter((d) => d.categoryCode === "Street") || null;
          _category?.length && this.street$.next(_category);
        }),
        tap(({ items }) => {
          const _landTypes =
            items?.filter((d) => d.categoryCode === "LandType") || null;
          _landTypes?.length && this.landTypes$.next(_landTypes);
        }),
        tap(({ items }) => {
          const _flm =
            items?.filter((d) => d.categoryCode === "FloorMaterial") || null;
          _flm?.length && this.floorMaterial$.next(_flm);
        }),
        tap(({ items }) => {
          const _dr =
            items?.filter((d) => d.categoryCode === "Direction") || null;
          _dr?.length && this.direction$.next(_dr);
        }),
        tap(({ items }) => {
          const _stF =
            items?.filter((d) => d.categoryCode === "StatusFurniture") || null;
          _stF?.length && this.statusFurniture$.next(_stF);
        }),
        tap(({ items }) => {
          const _juridical =
            items?.filter((d) => d.categoryCode === "Juridical") || null;
          _juridical?.length && this.juridical$.next(_juridical);
        }),
        tap(({ items }) => {
          const _cateStreet =
            items?.filter((d) => d.categoryCode === "CateStreet") || null;
          _cateStreet?.length && this.cateStreet$.next(_cateStreet);
        }),
        tap(({ items }) => {
          const _parking =
            items?.filter((d) => d.categoryCode === "Parking") || null;
          _parking?.length && this.parking$.next(_parking);
        }),
        tap((_) => {
          //  Chỉ update IndexDB khi có request API.
          this.neededUpdateDB && this.saveToIndexDB();
          // reset variable;
          this.neededUpdateDB = false;
        })
      )
      .subscribe();
  }

  private checkIndexDb(): void {
    const appVersion = environment.appVersion;
    const appVersionCurrent = this.tokenStorageService.get(KEY.APP_VERSION);

    // Lấy mới dư liệu khi thay đổi version app || k có data trong indexDB
    if (appVersion !== appVersionCurrent || !this.category$.getValue()) {
      this.tokenStorageService.set(KEY.APP_VERSION, appVersion);
      this.getDataFromApi();
    }
  }

  /**
   * Lấy tất cả data từ IndexDB.
   */
  private getAllDataFromIndexDB(): void {
    Object.keys(STORAGE_DB_KEY).forEach((key) => {
      this.getDataFromIndexDB(STORAGE_DB_KEY[key]);
    });
  }

  /**
   *  Update indexDB.
   */
  private saveToIndexDB(): void {
    this.cities$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.cities$.getValue() }],
        STORAGE_DB_KEY.cities
      );
    this.districts$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.districts$.getValue() }],
        STORAGE_DB_KEY.districts
      );
    this.towns$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.towns$.getValue() }],
        STORAGE_DB_KEY.towns
      );

    this.street$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.street$.getValue() }],
        STORAGE_DB_KEY.street
      );

    this.landTypes$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.landTypes$.getValue() }],
        STORAGE_DB_KEY.land_type
      );

    this.direction$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.direction$.getValue() }],
        STORAGE_DB_KEY.direction
      );

    this.floorMaterial$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.floorMaterial$.getValue() }],
        STORAGE_DB_KEY.floorMaterial
      );

    this.parking$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.parking$.getValue() }],
        STORAGE_DB_KEY.parking
      );

    this.statusFurniture$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.statusFurniture$.getValue() }],
        STORAGE_DB_KEY.statusFurniture
      );

    this.cateStreet$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.cateStreet$.getValue() }],
        STORAGE_DB_KEY.cate_street
      );

    this.juridical$.value.length &&
      this.storageIntoIndexDB(
        [{ data: this.juridical$.getValue() }],
        STORAGE_DB_KEY.juridical
      );
  }

  /**
   * Get all category from API and save them into IndexDB
   */
  getDataFromApi() {
    // Call get all-type from api when needed.
    if (!this.isLoading$.value) {
      this.isLoading$.next(true);
      this.baseHttpService
        .get(API_URL.allCategory)
        .pipe(
          tap((_) => (this.neededUpdateDB = true)),
          tap((data) => this.category$.next(data))
        )
        .subscribe();
    }
  }

  /**
   * Store category
   * @param data
   * @param key
   */
  storageIntoIndexDB(data: any[], key: string) {
    this.dbService.bulkAdd(key, data);
  }

  /**
   * Get all category
   * @param store
   * @private
   */
  private getDataFromIndexDB(store: string): void {
    this.dbService
      .getAll(store)
      .pipe(
        map((data) => {
          const t: any = data[0];
          if (t && t.data && t.data.length > 0) {
            this.category$.next({ items: t.data });
          } else {
            this.getDataFromApi();
          }
        })
      )
      .subscribe();
  }

  /**
   * Get list of cities from IDB
   */
  getAllCity(): Observable<any[]> {
    if (!this.cities$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.cities);
    return this.cities$.asObservable();
  }

  /**
   * Get list of town from IDB
   */
  getAllTown(): Observable<any[]> {
    if (!this.towns$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.towns);
    return this.towns$.asObservable();
  }

  /**
   * Get list of district
   */
  getAllDistrict(): Observable<any[]> {
    if (!this.districts$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.districts);
    return this.districts$.asObservable();
  }

  /**
   * Get list of direction
   */
  getAllDirection(): Observable<any[]> {
    if (!this.direction$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.direction);
    return this.direction$.asObservable();
  }

  /**
   * Get list of floor materials
   */
  getAllFloorMaterial(): Observable<any[]> {
    if (!this.floorMaterial$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.floorMaterial);
    return this.floorMaterial$.asObservable();
  }

  /**
   * Get list of street
   */
  getAllStreet(): Observable<any[]> {
    if (!this.street$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.street);
    return this.street$.asObservable();
  }

  /**
   * Get all realEstate types
   */
  getAllType(): Observable<any[]> {
    if (!this.landTypes$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.land_type);
    return this.landTypes$.asObservable();
  }

  /**
   * Get all parking
   */
  getAllParking(): Observable<any[]> {
    if (!this.parking$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.parking);
    return this.parking$.asObservable();
  }

  /**
   * Get all statusFurniture$
   */
  getAllStatusFurniture(): Observable<any[]> {
    if (!this.statusFurniture$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.statusFurniture);
    return this.statusFurniture$.asObservable();
  }

  /**
   * Get all statusFurniture$
   */
  getAllJuridical(): Observable<any[]> {
    if (!this.juridical$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.juridical);
    return this.juridical$.asObservable();
  }

  /**
   * Get all CateStreet$
   */
  getAllCateStreet(): Observable<any[]> {
    if (!this.cateStreet$.getValue().length)
      this.getDataFromIndexDB(STORAGE_DB_KEY.cate_street);
    return this.cateStreet$.asObservable();
  }
}
