import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatViewVoting",
})
export class FormatViewVotingPipe implements PipeTransform {
  transform(value: any): any {
    // Chuyển số thành string sau đó xét điều kiện độ dài lớn hơn 3 thì chuyển slit thêm k vào vị trí thứ 2
    let valueView = value?.toString();
    switch (valueView?.length) {
      case 4:
        return valueView.slice(0, 1) + "k" + valueView.slice(1, 2);
      case 5:
        return valueView.slice(0, 2) + "k" + valueView.slice(2, 3);
      case 6:
        return valueView.slice(0, 3) + "k";
      case 7:
        return valueView.slice(0, 1) + "Tr";
      default:
        return value;
    }
  }
}
