import { Injectable } from "@angular/core";
import { KEY } from "@constants/backend-url.constants";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { HISTORY_TYPE } from "@constants/common";
import { MD5 } from "object-hash";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HistoryUserService {
  constructor(private storageService: TokenStorageService) {}

  public save(key: string, data: any): void {
    switch (key) {
      case HISTORY_TYPE.COMPARE: {
        this.saveForCompare(data);
        break;
      }
      case HISTORY_TYPE.DRAW: {
        break;
      }
      case HISTORY_TYPE.DETAILS: {
        this.saveForDetail(data);
        break;
      }
      case HISTORY_TYPE.SEARCH: {
        const _key = MD5(data?.params?.address);
        this.saveForSearch({ key: _key, value: data?.params });
        break;
      }
      case HISTORY_TYPE.FAVORITE: {
        break;
      }
    }
  }

  public get(key: string) {
    return new Observable((obsever) => {
      const values = this.storageService.get(key);
      obsever.next(values);
    });
  }

  private saveForDraw({ url, params }: any): void {
    const items = this.storageService.get(KEY.COMPARE_HISTORY);
    const index = items.findIndex((p) => p.id === params.id);
    if (index === -1) {
      items.unshift({
        nameSearch: params.address || "",
        url: url,
      });
    } else if (index > 0) {
      items.slice(index, 1);
    }

    this.storageService.set(KEY.SEARCH_HISTORY, items);
  }

  private saveForCompare({ url, params }: any): void {
    const items = this.storageService.get(KEY.COMPARE_HISTORY);
    const index = items.findIndex((p) => p.id === params.id);
    if (index === -1) {
      items.unshift({
        nameSearch: params.address || "",
        url: url,
      });
    } else if (index > 0) {
      items.slice(index, 1);
    }

    this.storageService.set(KEY.SEARCH_HISTORY, items);
  }

  private saveForDetail({ url, params }: any): void {
    const items = this.storageService.get(KEY.DETAIL_HISTORY);
    const it = new Map(items);

    const index = items.findIndex((p) => p.id === params.id);
    if (index === -1) {
      items.unshift({
        nameSearch: params.address || "",
        url: url,
      });
    } else if (index > 0) {
      items.slice(index, 1);
    }

    this.storageService.set(KEY.SEARCH_HISTORY, items);
  }

  private saveForSearch({ key, value }: any): void {
    // lấy dữ liệu từ localstore về
    const items = this.storageService.get(KEY.SEARCH_HISTORY) || [];
    // tìm vị trí phần tử trùng
    value["key"] = key;
    const index = items.findIndex((sp) => {
      return sp.key === key;
    });

    if (index === -1) {
      items.unshift(value);
    } else if (index > 0) {
      items.slice(index, 1);
    }

    this.storageService.set(KEY.SEARCH_HISTORY, items);
  }
}
