import { Component, Input } from "@angular/core";
import Swiper from "swiper";
import { SwiperComponent } from "swiper/angular";

@Component({
    selector: "app-properties-toolbar",
    templateUrl: "./properties-toolbar.component.html",
    styleUrls: ["./properties-toolbar.component.scss"],
})
export class PropertiesToolbarComponent {
    @Input() heading: string;
    @Input() subHeading: string;
    @Input() swiperInstance: SwiperComponent | undefined;
    public swiperEnd: boolean = false;
    public swiperBegin: boolean = true;

    public onNextSlide() {
        if (this.swiperInstance) {
            const swiper = this.swiperInstance.swiperRef;
            swiper.slideNext();
            this.updateSlideStatus(swiper);
        }
    }

    public onPrevSlide() {
        if (this.swiperInstance) {
            const swiper = this.swiperInstance.swiperRef;
            swiper.slidePrev();
            this.updateSlideStatus(swiper);
        }
    }

    private updateSlideStatus(swiper: Swiper) {
        this.swiperEnd = swiper.isEnd;
        this.swiperBegin = swiper.isBeginning;
    }
}
