import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { MatDialogRef } from "@angular/material/dialog";
import { DEFINED_FILTER } from "@constants/common";

@Component({
  selector: "app-select-filter",
  templateUrl: "./select-filter.component.html",
  styleUrls: ["./select-filter.component.scss"],
})
export class SelectFilterComponent implements OnInit {
  @Input() searchLandParams: FormGroup;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  public isMobileScreen: boolean;
  public DEFINED_FILTER = DEFINED_FILTER;
  public selectForm: FormControl = new FormControl(this.DEFINED_FILTER[0]);
  public isOpen = false;

  constructor(
    public mediaMatcher: MediaMatcher,
    public dialogRef: MatDialogRef<SelectFilterComponent>
  ) {
    this.isMobileScreen = mediaMatcher.matchMedia("(max-width: 599px)").matches;
  }

  ngOnInit(): void {
    this.selectForm.valueChanges.subscribe((data) => {
      this.updateSearchParams(data);
    });

    const { orderColumns, orderDirects } = this.searchLandParams.getRawValue();
    if (orderColumns && orderDirects) {
      const item = this.DEFINED_FILTER.find(
        (d) =>
          d.orderColumns === orderColumns && d.orderDirects === orderDirects
      );
      item && this.selectForm.setValue(item);
    } else {
      this.updateSearchParams(this.selectForm.value);
    }
  }

  private updateSearchParams({ orderColumns, orderDirects }): void {
    this.searchLandParams?.patchValue({
      orderColumns,
      orderDirects,
    });
  }
}
