import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { emailValidator } from "@utils/app-validators";
import { ContactService } from "@services/crm/contact.service";
import { KEY } from "@constants/backend-url.constants";
import { TokenStorageService } from "@services/storage/token-storage.service";
import { CONTACT_TYPE } from "@constants/common";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Menu } from "app/public/modules/app-header/configs/menu.model";
import { MenuService } from "app/public/modules/app-header/configs/menu.service";
import { NotifyService } from "@services/common/notify.service";
import { LANGUAGES } from "@constants/language.constant";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    providers: [MenuService],
})
export class FooterComponent implements OnInit {
    @Input() isShowSubscribeNews: boolean = true;
    @Input() isShowFooterMenu: boolean = true;

    public feedbackForm: FormGroup;
    public subscribeForm: FormGroup;
    public userName: string;
    public menuItems: Array<Menu>;

    public readonly APP_LANGUAGE = LANGUAGES;

    constructor(
        public formBuilder: FormBuilder,
        private contactService: ContactService,
        private storageService: TokenStorageService,

        private menuService: MenuService,
        private notify: NotifyService
    ) {
        this.userName = this.storageService.get(KEY.USER_LOGIN)?.username || "";
    }

    ngOnInit() {
        this.feedbackForm = this.formBuilder.group({
            email: [
                "",
                Validators.compose([Validators.required, emailValidator]),
            ],
            content: ["", Validators.required],
            submitter: [this.userName],
            type: [CONTACT_TYPE.OTHER],
        });
        this.subscribeForm = this.formBuilder.group({
            email: [
                "",
                Validators.compose([Validators.required, emailValidator]),
            ],
        });

        this.menuItems = this.menuService.getMenuItems();
    }

    public onFeedbackFormSubmit(): void {
        if (this.feedbackForm.valid) {
            const payload = this.feedbackForm.getRawValue();
            this.contactService
                .addContact(payload)
                .pipe(
                    catchError((err) => {
                        this.handleError();
                        return throwError(err);
                    })
                )
                .subscribe((_) => {
                    this.notify.success(
                        "THANKS_FOR_FEEDBACK"
                    );
                });
        }
    }

    public onSubscribeFormSubmit(): void {
        if (this.subscribeForm.valid) {
            const payload = this.subscribeForm.getRawValue();
            this.contactService
                .addContact(payload)
                .pipe(
                    catchError((err) => {
                        this.handleError();
                        return throwError(err);
                    })
                )
                .subscribe((_) => {
                    this.notify.success(
                        "FOOTER_MESSAGE"
                    );
                });
        }
    }

    private handleError() {
        this.notify.error("ERROR_OCCURRED");
    }
}
